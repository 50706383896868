declare global {
    interface String {
        toHHMM(): String;
        toHHMMSS(): String;
        matchAll(pattern: RegExp): Array<String>;
        format(...args): String;
        isNullOrEmpty(value?: any): Boolean;
        capitalize(): String
    }
}


String.prototype.toHHMM = function () {
    if (!(/^[0-5][0-9]:[0-5][0-9]/).test(this)) {
        var secNum = parseInt(this, 10); // don't forget the second parm
        var hours = Math.floor(secNum / 3600);
        var minutes = Math.floor((secNum - (hours * 3600)) / 60);
        var hoursString = (hours < 10 ? ("0" + hours.toString()) : hours.toString());
        var minutesString = (minutes < 10 ? ("0" + minutes.toString()) : minutes.toString());
        var time = hoursString + ':' + minutesString;
        return time;
    }
    return this.replace(/:[0-5][0-9]$/, '');
};

String.prototype.toHHMMSS = function () {
    var secNum = parseInt(this, 10); // don't forget the second parm
    var hours = Math.floor(secNum / 3600);
    var minutes = Math.floor((secNum - (hours * 3600)) / 60);
    var seconds = secNum - (hours * 3600) - (minutes * 60);
    var hoursString = (hours < 10 ? ("0" + hours.toString()) : hours.toString());
    var minutesString = (minutes < 10 ? ("0" + minutes.toString()) : minutes.toString());
    var secondsString = (seconds < 10 ? ("0" + seconds.toString()) : seconds.toString());
    var time = hoursString + ':' + minutesString + ':' + secondsString;
    return time;
};

String.prototype.matchAll = function(pattern){
    var transpose = (a) => {
        return a[0].map(function (val, c) {
            return a.map(function (r) {
              return r[c];
            });
          });
    };
    
    var data = this;
    var regex = pattern;
    var matches = [];
    
    var result = regex.exec(data);
    matches.push(result);
   
    if(matches.length == 1 && matches[0] == null){
        matches[0] = this;
        return matches;
    }

    matches = matches.length > 0 ? transpose(matches) : Object.keys(data).map(function(i){
        return data[i];
    }).join("");
    
    return matches;
};

String.prototype.format = function (...args) {
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
    });
};

String.prototype.isNullOrEmpty = function (value) {
    if (typeof value != 'undefined') {
        if (typeof value != 'string') {
            throw 'String.isNullOrEmpty accepts only string type values';
        }
        return (value.length == 0);
    } else {
        return (this.length == 0);
    }
};

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

export { }