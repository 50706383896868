import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';
import { Group } from '../domain/group';

@Injectable()
export class GroupService {

    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getGroup(token:string, id:string): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.GET, "users/groups/"+id, token, {});
    }

    createGroup(token:string, group:Group): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "users/groups/create", token, 
        {
            name: group.name,
            description: group.description ?? null,
            serviceInstanceId: group.serviceInstanceId
        });
    }

    updateGroup(token:string, group:Group): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.PUT, "users/groups/update", token,
        {
            id: group.id,
            name: group.name,
            description: group.description ?? null,
            serviceInstanceId: group.serviceInstanceId
        });
    }

    deleteGroup(token:string, id:string): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.DELETE, "users/groups/"+id, token, {});
    }

    getGroupsList(token:string, event:LazyLoadEvent, serviceInstanceId:string): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "users/groups/list", token, {
            filters:event?.filters ?? null,
            filtersGroup: {
                serviceInstanceId: serviceInstanceId ?? null
            },
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    }
}