<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
    <div class="card">
        <p-toolbar styleClass="p-mb-4">
            <div class="p-toolbar-group-left" >
				<div class="p-d-flex p-flex-column p-flex-md-row">
					<div style="margin-right: 2rem;">
						<label for="filter_serviceIstances">Service istances</label>
						<br/>
						<p-dropdown [options]="serviceInstancesFilter" #dropdown_serviceIstances placeholder="Select service istance"
						(onChange)="getList(null)" [showClear]="true"
						[style]="{'min-width':'100%'}" optionLabel="name" name="serviceIstance" optionValue="id"></p-dropdown>
					</div>
				</div>
			</div>
            <div class="p-toolbar-group-right">
                <button pButton label="Create Customer" *ngIf="userHasPermission('CAN_ADD_CUSTOMER')" (click)="createCustomer()" class="p-button-oris"></button>
            </div>
        </p-toolbar>
        <p-table #dtCustomer [value]="customerList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
					[globalFilterFields]="['companyName', 'vatNumber', 'fiscalCode', 'emailAddress', 'sdiCode', 'pecEmailAddress', 'erpReferenceCode']"
					(onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
					[rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="customerListLoading">
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
							Customer List
							<span class="p-input-icon-left">
								<i class="pi pi-search"></i>
								<input pInputText type="text" (input)="dtCustomer.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
							</span>
						</div>
					</ng-template>
					<ng-template pTemplate="header">
						<tr>
							<th style="width: 3rem"></th>
							<th pSortableColumn="company_name">
								<div class="p-d-flex p-jc-between p-ai-center">
									Company Name
									<p-sortIcon field="company_name"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="guid">
								<div class="p-d-flex p-jc-between p-ai-center">
									GUID
									<p-sortIcon field="guid"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="vat_number">
								<div class="p-d-flex p-jc-between p-ai-center">
									Vat Number
									<p-sortIcon field="version"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="fiscal_code">
								<div class="p-d-flex p-jc-between p-ai-center">
									Fiscal Code
									<p-sortIcon field="fiscal_code"></p-sortIcon>
								</div>
							</th>
                            <th pSortableColumn="email_address">
								<div class="p-d-flex p-jc-between p-ai-center">
									Email Address
									<p-sortIcon field="email_address"></p-sortIcon>
								</div>
							</th>
                            <th pSortableColumn="sdicode">
								<div class="p-d-flex p-jc-between p-ai-center">
									SDI Code
									<p-sortIcon field="sdicode"></p-sortIcon>
								</div>
							</th>
                            <th pSortableColumn="pec_email_address">
                                <div class="p-d-flex p-jc-between p-ai-center">
									PEC Email Address
									<p-sortIcon field="pec_email_address"></p-sortIcon>
								</div>
                            </th>
                            <th pSortableColumn="erp_reference_code">
                                <div class="p-d-flex p-jc-between p-ai-center">
									ERP Reference Code
									<p-sortIcon field="erp_reference_code"></p-sortIcon>
								</div>
                            </th>
							<th style="width: 8rem"></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-customer let-expanded="expanded">

						<tr class="p-selectable-row">
							<td>
								<div *ngIf="customer.instances.length > 0">
									<button type="button" pButton pRipple [pRowToggler]="customer" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
								</div>
							</td>
							<td>
								{{customer.companyName}}
							</td>
							<td>
								{{customer.guid}}
							</td>
                            <td>
                                {{customer.vatNumber}}
                            </td>
							<td>
								{{customer.fiscalCode}}
							</td>
                            <td>
                                {{customer.emailAddress}}
                            </td>
                            <td>
                                {{customer.sdiCode}}
                            </td>
                            <td>
                                {{customer.pecEmailAddress}}
                            </td>
                            <td>
                                {{customer.erpReferenceCode}}
                            </td>
                            <td>
                                <button pButton type="button" title="Edit" *ngIf="userHasPermission('CAN_ADD_CUSTOMER')" icon="pi pi-pencil"
									class="p-button-rounded p-button-text" (click)="editCustomer(customer.id)"
									></button>
								<button pButton type="button" title="Delete" *ngIf="userHasPermission('CAN_DELETE_CUSTOMER_BY_ID')" class="p-button-text"
									icon="pi pi-trash" (click)="deleteCustomer(customer.id, customer.companyName)"></button>
                            </td>
						</tr>
					</ng-template>
					<ng-template pTemplate="rowexpansion" let-customer>
						<td colspan="7">
							<div class="p-p-oris-5">
								<p-table [value]="customer.instances" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true">
									<ng-template pTemplate="header">
										<tr>
											<th pSortableColumn="name">
												<div class="p-d-flex p-jc-between p-ai-center">
													Name
												</div>
											</th>
											<th pSortableColumn="is_enabled">
												<div class="p-d-flex p-jc-between p-ai-center">
													Enabled
												</div>
											</th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-instance>
										<tr class="p-selectable-row">
											<td>
												{{instance.name}}
											</td>
											<td>
												<div *ngIf="(instance.isEnabled)">
													<i class="pi pi-check"></i>
												</div>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td>No records found</td>
										</tr>
									</ng-template>
								</p-table>
							</div>
						</td>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td>No records found</td>
					</tr>
				</ng-template>
		</p-table>
    </div>
</div>

<form #createCustomerForm="ngForm" (ngSubmit)="createCustomerForm.form.valid && saveCustomer()">
	<p-dialog header="New Customer" [(visible)]="customerCreateModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="customerNew_companyName">Company Name</label>
			<input type="text" pInputText id="customerNew_companyName" name="customerNew_companyName"
				[(ngModel)]="customer_new.companyName" #company_name='ngModel' required/>
                <div *ngIf="createCustomerForm.submitted && company_name.invalid" class="error-login-info">
                    <small *ngIf="company_name.errors?.required" class="p-error">Enter company name</small>
                </div>
			</div>
			<div class="p-col-6">
				<label for="customerNew_vatNumber">Vat Number</label>
				<input type="text" pInputText id="customerNew_vatNumber" name="customerNew_vatNumber"
				[(ngModel)]="customer_new.vatNumber" #vat_number='ngModel' required/>
                <div *ngIf="createCustomerForm.submitted && vat_number.invalid" class="error-login-info">
                    <small *ngIf="vat_number.errors?.required" class="p-error">Enter vat number</small>
                </div>
			</div>
		</div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="customerNew_fiscalCode">Fiscal Code</label>
                <input type="text" pInputText id="customerNew_fiscalCode" name="customerNew_fiscalCode"
                [(ngModel)]="customer_new.fiscalCode" #fiscal_code='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="customerNew_sdiCode">SDI Code</label>
                <input type="text" pInputText id="customerNew_sdiCode" name="customerNew_sdiCode"
                [(ngModel)]="customer_new.sdiCode" #fiscal_code='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="customerNew_erpReferenceCode">ERP Reference Code</label>
                <input type="text" pInputText id="customerNew_erpReferenceCode" name="customerNew_erpReferenceCode"
                [(ngModel)]="customer_new.erpReferenceCode" #fiscal_code='ngModel'>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="customerNew_emailAddress">Email Address</label>
                <input type="email" pInputText id="customerNew_emailAddress" name="customerNew_emailAddress"
                [(ngModel)]="customer_new.emailAddress" #email_address='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="customerNew_pecEmailAddress">PEC Email Address</label>
                <input type="email" pInputText id="customerNew_pecEmailAddress" name="customerNew_pecEmailAddress"
                [(ngModel)]="customer_new.pecEmailAddress" #pec_email_address='ngModel'>
            </div>
        </div>
		<div class="p-formgroup-inline">
			<div class="p-col-4">
				<label for="customerNew_languageReference">Language Reference</label>
				<input type="text" pInputText id="customerNew_languageReference" name="customerNew_languageReference" maxlength="2"
				[(ngModel)]="customer_new.languageReference" #language_reference='ngModel' oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);">
			</div>
			<div class="p-col-4">
				<label for="customerNew_country">Country</label>
				<input type="text" pInputText id="customerNew_country" name="customerNew_country" maxlength="2"
				[(ngModel)]="customer_new.country" #country='ngModel' oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);">
			</div>
		</div>
		<br />
		<br />
		<br />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeCreateCustomer()"
				class="p-button-danger"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit"
				class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>

<form #editCustomerForm="ngForm" (ngSubmit)="editCustomerForm.form.valid && updateCustomer()">
	<p-dialog header="Edit Customer" [(visible)]="customerEditModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="customerEdit_companyName">Company Name</label>
			<input type="text" pInputText id="customerEdit_companyName" name="customerEdit_companyName"
				[(ngModel)]="customer_edit.companyName" #edit_company_name='ngModel' required/>
                <div *ngIf="createCustomerForm.submitted && company_name.invalid" class="error-login-info">
                    <small *ngIf="company_name.errors?.required" class="p-error">Enter company name</small>
                </div>
			</div>
			<div class="p-col-6">
				<label for="customerEdit_vatNumber">Vat Number</label>
				<input type="text" pInputText id="customerEdit_vatNumber" name="customerEdit_vatNumber"
				[(ngModel)]="customer_edit.vatNumber" #edit_vat_number='ngModel' required/>
                <div *ngIf="createCustomerForm.submitted && vat_number.invalid" class="error-login-info">
                    <small *ngIf="vat_number.errors?.required" class="p-error">Enter vat number</small>
                </div>
			</div>
		</div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="customerEdit_fiscalCode">Fiscal Code</label>
                <input type="text" pInputText id="customerEdit_fiscalCode" name="customerEdit_fiscalCode"
                [(ngModel)]="customer_edit.fiscalCode" #edit_fiscal_code='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="customerEdit_sdiCode">SDI Code</label>
                <input type="text" pInputText id="customerEdit_sdiCode" name="customerEdit_sdiCode"
                [(ngModel)]="customer_edit.sdiCode" #edit_fiscal_code='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="customerEdit_erpReferenceCode">ERP Reference Code</label>
                <input type="text" pInputText id="customerEdit_erpReferenceCode" name="customerEdit_erpReferenceCode"
                [(ngModel)]="customer_edit.erpReferenceCode" #edit_fiscal_code='ngModel'>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="customerEdit_emailAddress">Email Address</label>
                <input type="email" pInputText id="customerEdit_emailAddress" name="customerEdit_emailAddress"
                [(ngModel)]="customer_edit.emailAddress" #edit_email_address='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="customerEdit_pecEmailAddress">PEC Email Address</label>
                <input type="email" pInputText id="customerEdit_pecEmailAddress" name="customerEdit_pecEmailAddress"
                [(ngModel)]="customer_edit.pecEmailAddress" #edit_pec_email_address='ngModel'>
            </div>
        </div>
		<br />
		<br />
		<br />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeEditCustomer()"
				class="p-button-danger"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit"
				class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>