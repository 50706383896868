import { Directive, forwardRef, Attribute} from "@angular/core";
import { Validator, AbstractControl, NG_VALIDATORS} from "@angular/forms";

@Directive({
  selector: '[validateEmail]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidator), multi: true}
  ]
})

export class EmailValidator implements Validator {
  constructor (
    @Attribute('validateEmail')
    public invalidEmail: boolean
  ) {}

  validate(ctrl: AbstractControl): {[key: string]: any} {
    let email = ctrl.value;

    let hasSpecial = false;

    const specialcharRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
    if (specialcharRegex.test(email)) {
      hasSpecial = true;
    }

    let counter = 0;
    let checks = [hasSpecial];
    for (let i = 0; i < checks.length; i++) {
      if (checks[i]) {
        counter += 1;
      }
    }

    if (counter < 1) {
      return { invalidEmail: true }
    } else {
      return null;
    }
  }
}