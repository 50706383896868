import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { FileUpload } from 'primeng/fileupload';
import { of } from 'rxjs';
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppGlobals } from 'src/app/app.globals';
import { AppBreadcrumbService } from 'src/app/core/breadcumb/app.breadcrumb.service';
import { ServiceInstance } from '../instances/domain/instance';
import { InstanceService } from '../instances/service/instanceservice';
import { DocumentCategory } from './domain/documentCategory';
import { DocumentCategoryService } from './service/documentcategoryservice';

@Component({
    templateUrl: './documentCategory.component.html',
    styleUrls: ['./documentCategory.component.scss']
})

export class DocumentCategoryComponent {
    private token: string;
    private event_lazy_local:LazyLoadEvent;
    public documentCategoryNew: DocumentCategory = {};
    public documentCategoryEdit: DocumentCategory = {};
    public documentList: DocumentCategory[] = [];
    public serviceInstanceList: ServiceInstance[];
    public serviceInstancesFilter: ServiceInstance[];
    public bodyLoaded = false;

    public serviceDocumentsCategoriesList: DocumentCategory[];
    public serviceDocumentsCategoriesFilter: DocumentCategory[];
    public filteredServiceDocumentsCategoriesList: DocumentCategory[];

    public documentFile: File[] = [];
    public documentCategoryCreateModal: boolean = false;
    public documentCategoryEditModal: boolean = false;
    public documentListLoading: boolean = true;
    public warningModal: boolean;
    public totalRecords: number;
    public itemsPerPage: number = 25;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;
    @ViewChild("document") 
    document: FileUpload;
    @ViewChild("createDocumentCategoryForm")
    createDocumentCategoryForm: NgForm;
    @ViewChild("editDocumentCategoryForm")
    editDocumentCategoryForm: NgForm;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private documentCategoryService: DocumentCategoryService,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService){
        let context = this;

        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([ AppGlobals.setBreadCrumb('Document Categories', '/documents/categories', AppGlobals.userHasPermission("IS_CLIENT")) ]);
        context.event_lazy_local = {} as LazyLoadEvent;
        context.setServiceIstanceFilter();
        context.setServiceInstancesDropdown();
        context.setServiceDocumentsCategoriesFilter();
        context.setServiceDocumentsCategoriesDropdown();
    }

    public onAutoUpload(event, fileuploadElement){
        let context = this;
        let fileup = <File>event.files[0];
        context.documentFile.push(fileup);
        fileuploadElement.clear();
    }

    private setServiceIstanceFilter(){
        let context = this;
        context.serviceInstaceService.getServiceInstanceList(context.token, null, null, null).pipe(
                tap((res) => {
                    if(res.data != null){
                        context.serviceInstancesFilter = res.data.map((service) => {
                            return {
                            id: service.id,
                            name: service.name
                        }});
                    }
                }),
                catchError(() => {
                    return of();
            })
        ).subscribe();
    }

    private setServiceInstancesDropdown(){
        let context = this;
        context.serviceInstaceService.getServiceInstanceList(context.token, null, null, null).pipe(
            tap((response) => {
                if(response.data != null){
                    context.serviceInstanceList = response.data.map((service) => {
                        return {
                            id: service.id,
                            name: service.name
                        }
                    });
                }
            }),
            catchError((error) => {
                return of();
        })).subscribe();
    }

    private setServiceDocumentsCategoriesFilter(){
        let context = this;
        context.documentCategoryService.getDocumentCategoriesList(context.token, null, null).pipe(
                tap((res) => {
                    if(res.data != null){
                        context.serviceDocumentsCategoriesFilter = res.data.map((documentCategory) => {
                            return {
                            id: documentCategory.id,
                            name: documentCategory.name
                        }});
                    }
                }),
                catchError(() => {
                    return of();
            })
        ).subscribe();
    }

    private setServiceDocumentsCategoriesDropdown(){
        let context = this;
        context.documentCategoryService.getDocumentCategoriesList(context.token, null, null).pipe( tap((response) => {
            if(response.data != null){
                context.serviceDocumentsCategoriesList = response.data.map((category) => {
                    return {
                        id: category.id,
                        name: category.name,
                        serviceInstanceId: category.serviceInstanceId
                    }
                });
            }
        }),catchError((error) => {return of();})).subscribe();
    }

    public filterCategoriesByInstance(event){
        var context = this;
        event.value;

        context.filteredServiceDocumentsCategoriesList = context.serviceDocumentsCategoriesList.filter((category) => {
            if(category.serviceInstanceId == event.value){
                return {
                    id: category.id,
                    name: category.name,
                    serviceInstanceId: category.serviceInstanceId
                }
            }
        });
        var i = 1;
    }

    public createDocumentCategory(){
        let context = this;
        context.documentCategoryNew = {} as DocumentCategory;
        context.setServiceDocumentsCategoriesFilter();
        context.setServiceDocumentsCategoriesDropdown();
        context.documentCategoryCreateModal = true;
       
    }

    public closeCreateDocumentCategory()
    {
        this.documentCategoryNew = {} as DocumentCategory;
        this.documentCategoryCreateModal = false;
        this.createDocumentCategoryForm.resetForm();
    }

    public editDocument(id:string){
        let context = this;
        context.setServiceDocumentsCategoriesFilter();
        context.setServiceDocumentsCategoriesDropdown();
        context.documentCategoryService.getDocumentCategory(context.token, id).pipe(
            tap((response) => {
                context.documentCategoryEdit = {
                    id: response.data.id,
                    name: response.data.name,
                    description: response.data.description,
                    father: response.data.fatherId,
                    serviceInstanceId: response.data.serviceInstanceId,
                } as DocumentCategory;

                context.filteredServiceDocumentsCategoriesList = context.serviceDocumentsCategoriesList.filter((category) => {
                    if(category.serviceInstanceId == response.data.serviceInstanceId){
                        return {
                            id: category.id,
                            name: category.name,
                            serviceInstanceId: category.serviceInstanceId
                        }
                    }
                });
                context.documentCategoryEditModal = true;
            }),catchError((error) => {
                context.messageService.add({
                    severity:"error",
                    summary:"Error",
                    detail:error.message ?? error.error.title
                });
                context.documentCategoryEditModal = false;
                return of();
        }),finalize(() => {})).subscribe();
    }

    public closeEditDocumentCategory()
    {
        this.documentCategoryEdit = {} as DocumentCategory;
        this.documentCategoryEditModal = false;
        this.editDocumentCategoryForm.resetForm();
    }

    public updateDocumentCategory(){
        let context = this;
        context.documentCategoryService.updateDocumentCategory(context.token, context.documentCategoryEdit).pipe(
            tap((response) => {
                context.messageService.add({
                    severity:"success",
                    summary:"Success",
                    detail: response.message ?? "Success"
                });
                context.documentCategoryEditModal = false;
                context.getList(null);
                context.editDocumentCategoryForm.resetForm();
            }),catchError((res) => {
                context.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: res.error?.message ?? res.error.title
                });
                return of();
        }),finalize(() => { context.documentCategoryEditModal = false; })).subscribe();
    }

    public saveDocument(){
        let context = this;
        context.documentCategoryService.createDocumentCategory(context.token, context.documentCategoryNew).pipe(
            tap((res) => {
                context.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: res.message ?? "Success"
                });
            }),catchError((res) => {
                context.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: res.error?.message ?? res.error.title
                });
                return of();
            }),finalize(() => {
                context.documentCategoryCreateModal = false;
                context.createDocumentCategoryForm.resetForm();
                context.getList(null);
        })).subscribe();
    }

    public deleteDocumentCategory(id: string, category:string){
        let context = this;
        context.confirmationService.confirm({
            message: "You are about to delete '"+category+"'. Are you sure?",
            header: "Warning",
            icon: "pi pi-warning",
            acceptLabel: "Yes",
            rejectLabel: "No",
            accept: () => {
                context.documentCategoryService.deleteDocumentCategory(context.token, id).pipe(
                    tap((res) => {
                            context.messageService.add({
                                severity: "success",
                                summary: "Success",
                                detail: res.message ?? "Success"
                            });
                        }),catchError((res) => {
                            context.messageService.add({
                                severity: "error",
                                summary: "Error",
                                detail: res.error?.message ?? res.error.title
                            });
                            return of();
                        }),
                        finalize(() => {
                            context.getList(null);
                        })
                    ).subscribe();
            },reject: () => {
                return;
            }, rejectVisible: true });
    }
    
    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    public getList(event:LazyLoadEvent) {
        let context = this;
        context.documentList = [];
        context.bodyLoaded = false;
        if(event != null){
            context.event_lazy_local = event;
        }
        
        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;

        context.documentListLoading = true;
        context.documentCategoryService.getDocumentCategoriesList(context.token, context.event_lazy_local, serviceInstanceId).pipe(
            tap((res) => {
                    if(res.data != null){
                        let responseData = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + context.event_lazy_local.rows));
                        context.totalRecords = res.data.length;
                        responseData.forEach(documentCategory => {
                            context.documentList.push({
                                id: documentCategory.id,
                                name: documentCategory.name,
                                description: documentCategory.description,
                                serviceInstance:
                                    typeof documentCategory.instance !== "undefined" && documentCategory.instance !== null &&
                                    typeof documentCategory.instance.name !== "undefined" && documentCategory.instance.name !== null
                                        ? documentCategory.instance.name
                                        : "",
                                father: (documentCategory.fatherDocumentCategory != null ? documentCategory.fatherDocumentCategory.name : ''),
                                
                            });
                        });
                    }
                }),catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail:res.error?.message ?? res.error.title
                    })
                return of();
        }),finalize(() => {  context.bodyLoaded = true; context.documentListLoading = false;})).subscribe();
    }
}