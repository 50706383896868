import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AccordionModule } from "primeng/accordion";
import { ConfirmationService, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { RadioButtonModule } from "primeng/radiobutton";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SelectButtonModule } from "primeng/selectbutton";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { ToolbarModule } from "primeng/toolbar";
import { DocumentComponent } from "./document.component";
import { DocumentService } from "./service/documentservice";
import { FileUploadModule } from "primeng/fileupload";
import { DocumentCategoryComponent } from "./documentCategory.component";
import { DocumentCategoryService } from "./service/documentcategoryservice";

@NgModule({
    declarations:[
        DocumentComponent,
        DocumentCategoryComponent
    ],
    providers:[
        DatePipe,
        ConfirmationService,
        MessageService,
        DocumentService,
        DocumentCategoryService
    ],
    imports:[
        CommonModule, BrowserModule, BrowserAnimationsModule, ToastModule,
        SelectButtonModule, FormsModule, ReactiveFormsModule, DialogModule, ToolbarModule,
        TableModule, ConfirmDialogModule, HttpClientModule, InputTextareaModule,
        ButtonModule, TabViewModule, AccordionModule, TabMenuModule, InputTextModule,
        CheckboxModule, DropdownModule, RadioButtonModule, ScrollPanelModule, FileUploadModule
    ]
})
export class DocumentModule{}