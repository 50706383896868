<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
    <div class="card">
        <p-toolbar styleClass="p-mb-4">
            <div class="p-toolbar-group-left">
				<div class="p-d-flex p-flex-column p-flex-md-row">
					<div style="margin-right: 2rem;">
						
					</div>
				</div>
			</div>
            <div class="p-toolbar-group-right">
                <button pButton label="Create Supplier" *ngIf="userHasPermission('CAN_ADD_SUPPLIER')" (click)="createSupplier()" class="p-button-oris"></button>
            </div>
        </p-toolbar>
        <p-table #dtSupplier [value]="supplierList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
					[globalFilterFields]="['companyName', 'guid', 'vat_number', 'fiscal_code', 'email_address', 'country', 'city']"
					(onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
					[rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="supplierListLoading">
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
							Supplier List
							<span class="p-input-icon-left">
								<i class="pi pi-search"></i>
								<input pInputText type="text" (input)="dtSupplier.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
							</span>
						</div>
					</ng-template>
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="company_name">
								<div class="p-d-flex p-jc-between p-ai-center">
									Company Name
									<p-sortIcon field="company_name"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="guid">
								<div class="p-d-flex p-jc-between p-ai-center">
									GUID
									<p-sortIcon field="guid"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="vat_number">
								<div class="p-d-flex p-jc-between p-ai-center">
									Vat Number
									<p-sortIcon field="vat_number"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="fiscal_code">
								<div class="p-d-flex p-jc-between p-ai-center">
									Fiscal Code
									<p-sortIcon field="fiscal_code"></p-sortIcon>
								</div>
							</th>
                            <th pSortableColumn="email_address">
								<div class="p-d-flex p-jc-between p-ai-center">
									Email Address
									<p-sortIcon field="email_address"></p-sortIcon>
								</div>
							</th>
                            <th pSortableColumn="country">
								<div class="p-d-flex p-jc-between p-ai-center">
									Country
									<p-sortIcon field="country"></p-sortIcon>
								</div>
							</th>
                            <th pSortableColumn="city">
                                <div class="p-d-flex p-jc-between p-ai-center">
									City
									<p-sortIcon field="city"></p-sortIcon>
								</div>
                            </th>
							<th style="width: 8rem"></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-supplier let-expanded="expanded">

						<tr class="p-selectable-row">
							<td>
								{{supplier.companyName}}
							</td>
							<td>
								{{supplier.guid}}
							</td>
                            <td>
                                {{supplier.vatNumber}}
                            </td>
							<td>
								{{supplier.fiscalCode}}
							</td>
                            <td>
                                {{supplier.emailAddress}}
                            </td>
                            <td>
                                {{supplier.country}}
                            </td>
                            <td>
                                {{supplier.city}}
                            </td>
                            <td>
                                <button pButton type="button" *ngIf="userHasPermission('CAN_EDIT_SUPPLIER')" title="Edit" icon="pi pi-pencil"
									class="p-button-rounded p-button-text" (click)="editSupplier(supplier.id)"
									></button>
								<button pButton type="button" *ngIf="userHasPermission('CAN_DELETE_SUPPLIER_BY_ID')" title="Delete" class="p-button-text"
									icon="pi pi-trash" (click)="deleteSupplier(supplier.id, supplier.companyName)"></button>
                            </td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td>No records found</td>
						</tr>
					</ng-template>
				</p-table>
    </div>
</div>

<form #createSupplierForm="ngForm" (ngSubmit)="createSupplierForm.form.valid && saveSupplier()">
	<p-dialog header="New Supplier" [(visible)]="supplierCreateModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="supplierNew_companyName">Company Name</label>
			<input type="text" pInputText id="supplierNew_companyName" name="supplierNew_companyName"
				[(ngModel)]="supplier_new.companyName" #company_name='ngModel' required/>
                <div *ngIf="createSupplierForm.submitted && company_name.invalid" class="error-login-info">
                    <small *ngIf="company_name.errors?.required" class="p-error">Enter company name</small>
                </div>
			</div>
			<div class="p-col-6">
				<label for="supplierNew_vatNumber">Vat Number</label>
				<input type="text" pInputText id="supplierNew_vatNumber" name="supplierNew_vatNumber"
				[(ngModel)]="supplier_new.vatNumber" #vat_number='ngModel' required/>
                <div *ngIf="createSupplierForm.submitted && vat_number.invalid" class="error-login-info">
                    <small *ngIf="vat_number.errors?.required" class="p-error">Enter vat number</small>
                </div>
			</div>
		</div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="supplierNew_fiscalCode">Fiscal Code</label>
                <input type="text" pInputText id="supplierNew_fiscalCode" name="supplierNew_fiscalCode"
                [(ngModel)]="supplier_new.fiscalCode" #fiscal_code='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierNew_webSite">WebSite Address</label>
                <input type="text" pInputText id="supplierNew_webSite" name="supplierNew_webSite"
                [(ngModel)]="supplier_new.webSiteAddress" #website_address='ngModel'>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="supplierNew_emailAddress">Email Address</label>
                <input type="email" pInputText id="supplierNew_emailAddress" name="supplierNew_emailAddress"
                [(ngModel)]="supplier_new.emailAddress" #email_address='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierNew_pecEmailAddress">PEC Email Address</label>
                <input type="email" pInputText id="supplierNew_pecEmailAddress" name="supplierNew_pecEmailAddress"
                [(ngModel)]="supplier_new.pecEmailAddress" #pec_email_address='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierNew_phoneNumber">Phone Number</label>
                <input type="text" pInputText id="supplierNew_phoneNumber" name="supplierNew_phoneNumber"
                [(ngModel)]="supplier_new.phoneNumber" #phone_number='ngModel'>
            </div>
        </div>
        <div class="p-formgroup-inline">
			<div class="p-col-4">
				<label for="supplierNew_country">Country</label>
				<input type="text" pInputText id="supplierNew_country" name="supplierNew_country"
				[(ngModel)]="supplier_new.country" #country='ngModel'>
			</div>
            <div class="p-col-4">
                <label for="supplierNew_city">City</label>
				<input type="text" pInputText id="supplierNew_city" name="supplierNew_city"
				[(ngModel)]="supplier_new.city" #city='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierNew_district">District</label>
				<input type="text" pInputText id="supplierNew_district" name="supplierNew_district" maxlength="2"
				[(ngModel)]="supplier_new.district" #city='ngModel'>
            </div>
		</div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="supplierNew_address">Address</label>
                <input type="text" pInputText id="supplierNew_address" name="supplierNew_address"
                [(ngModel)]="supplier_new.address" #address='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierNew_zipCode">Zip Code</label>
                <input type="text" pInputText id="supplierNew_zipCode" name="supplierNew_zipCode"
                [(ngModel)]="supplier_new.zipCode" #zip_code='ngModel'>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-12">
                <label for="supplierNew_companyDescription">Company Description</label>
                <textarea id="supplierNew_companyDescription" pInputTextarea name="supplierNew_companyDescription" [(ngModel)]="supplier_new.companyDescription"
				rows="3" cols="20"></textarea>
            </div>
        </div>
		<br />
		<br />
		<br />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeCreateSupplier()"
				class="p-button-danger"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit"
				class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>
<form #editSupplierForm="ngForm" (ngSubmit)="editSupplierForm.form.valid && updateSupplier()">
	<p-dialog header="Edit Supplier" [(visible)]="supplierEditModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="supplierEdit_companyName">Company Name</label>
			<input type="text" pInputText id="supplierEdit_companyName" name="supplierEdit_companyName"
				[(ngModel)]="supplier_edit.companyName" #edit_company_name='ngModel' required/>
                <div *ngIf="editSupplierForm.submitted && edit_company_name.invalid" class="error-login-info">
                    <small *ngIf="edit_company_name.errors?.required" class="p-error">Enter company name</small>
                </div>
			</div>
			<div class="p-col-6">
				<label for="supplierEdit_vatNumber">Vat Number</label>
				<input type="text" pInputText id="supplierEdit_vatNumber" name="supplierEdit_vatNumber"
				[(ngModel)]="supplier_edit.vatNumber" #edit_vat_number='ngModel' required/>
                <div *ngIf="editSupplierForm.submitted && edit_vat_number.invalid" class="error-login-info">
                    <small *ngIf="edit_vat_number.errors?.required" class="p-error">Enter vat number</small>
                </div>
			</div>
		</div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="supplierEdit_fiscalCode">Fiscal Code</label>
                <input type="text" pInputText id="supplierEdit_fiscalCode" name="supplierEdit_fiscalCode"
                [(ngModel)]="supplier_edit.fiscalCode" #edit_fiscal_code='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierEdit_webSite">WebSite Address</label>
                <input type="text" pInputText id="supplierEdit_webSite" name="supplierEdit_webSite"
                [(ngModel)]="supplier_edit.webSiteAddress" #edit_website_address='ngModel'>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="supplierEdit_emailAddress">Email Address</label>
                <input type="email" pInputText id="supplierEdit_emailAddress" name="supplierEdit_emailAddress"
                [(ngModel)]="supplier_edit.emailAddress" #edit_email_address='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierEdit_pecEmailAddress">PEC Email Address</label>
                <input type="email" pInputText id="supplierEdit_pecEmailAddress" name="supplierEdit_pecEmailAddress"
                [(ngModel)]="supplier_edit.pecEmailAddress" #edit_pec_email_address='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierEdit_phoneNumber">Phone Number</label>
                <input type="text" pInputText id="supplierEdit_phoneNumber" name="supplierEdit_phoneNumber"
                [(ngModel)]="supplier_edit.phoneNumber" #edit_phone_number='ngModel'>
            </div>
        </div>
        <div class="p-formgroup-inline">
			<div class="p-col-4">
				<label for="supplierEdit_country">Country</label>
				<input type="text" pInputText id="supplierEdit_country" name="supplierEdit_country"
				[(ngModel)]="supplier_edit.country" #edit_country='ngModel'>
			</div>
            <div class="p-col-4">
                <label for="supplierEdit_city">City</label>
				<input type="text" pInputText id="supplierEdit_city" name="supplierEdit_city"
				[(ngModel)]="supplier_edit.city" #edit_city='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierEdit_district">District</label>
				<input type="text" pInputText id="supplierEdit_district" name="supplierEdit_district" maxlength="2"
				[(ngModel)]="supplier_edit.district" #edit_city='ngModel'>
            </div>
		</div>
        <div class="p-formgroup-inline">
            <div class="p-col-4">
                <label for="supplierEdit_address">Address</label>
                <input type="text" pInputText id="supplierEdit_address" name="supplierEdit_address"
                [(ngModel)]="supplier_edit.address" #edit_address='ngModel'>
            </div>
            <div class="p-col-4">
                <label for="supplierEdit_zipCode">Zip Code</label>
                <input type="text" pInputText id="supplierEdit_zipCode" name="supplierEdit_zipCode"
                [(ngModel)]="supplier_edit.zipCode" #edit_zip_code='ngModel'>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-12">
                <label for="supplierEdit_companyDescription">Company Description</label>
                <textarea id="supplierEdit_companyDescription" pInputTextarea name="supplierEdit_companyDescription" [(ngModel)]="supplier_edit.companyDescription"
				rows="3" cols="20"></textarea>
            </div>
        </div>
		<br />
		<br />
		<br />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeEditSupplier()"
				class="p-button-danger"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit"
				class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>