import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { FileUpload } from 'primeng/fileupload';
import { stringify } from 'querystring';
import { of } from 'rxjs';
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppBreadcrumbService } from 'src/app/core/breadcumb/app.breadcrumb.service';
import { ServiceInstance } from '../instances/domain/instance';
import { InstanceService } from '../instances/service/instanceservice';
import { Document } from './domain/document';
import { DocumentService } from './service/documentservice';
import { DocumentCategoryService } from './service/documentcategoryservice';
import { DocumentCategory } from './domain/documentCategory';
import { AppGlobals } from 'src/app/app.globals';
import { ActivatedRoute } from '@angular/router';
import { TreeModule } from 'primeng/tree';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})


export class DocumentComponent {
    private token: string;
    public document_new: Document = {};
    public document_edit: Document = {};
    public documentList: Document[] = [];
    public serviceInstanceList: ServiceInstance[];
    public serviceInstancesFilter: ServiceInstance[];
    public documentFile: File[] = [];
    public documentCreateModal: boolean = false;
    public documentEditModal: boolean = false;
    public documentListLoading: boolean = true;
    public warningModal: boolean;
    public totalRecords: number;
    private event_lazy_local:LazyLoadEvent;
    public serviceDocumentsCategoriesList: DocumentCategory[];
    public filteredServiceDocumentsCategoriesList: DocumentCategory[];
    public documentsStatus = [
        {'value': '0', 'label': 'Proposed'},
        {'value': '1', 'label': 'Accepted'},
        {'value': '2', 'label': 'Revision'}
    ];
    public itemsPerPage: number = 25;
    private categoryId: any;

    public documentUrl = "/assets/documents/";

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;
    @ViewChild("dropdown_documentsStatus")
    dropdown_documentsStatus: Dropdown;
    @ViewChild("document") document: FileUpload;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private documentCategoryService: DocumentCategoryService,
        private documentService: DocumentService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService) {
        let context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([ AppGlobals.setBreadCrumb('Document', '/documents', AppGlobals.userHasPermission("IS_CLIENT")) ]);
        context.setServiceDocumentsCategoriesDropdown()
        context.event_lazy_local = {} as LazyLoadEvent;
        context.categoryId = context.route.snapshot.paramMap.get('id');
        context.setServiceIstanceFilter();
        context.setServiceInstancesDropdown();

        var configurations = AppGlobals.getAppConfigurations();
        if(configurations.azureStorageEnabled){
            this.documentUrl = configurations.azureStorageDocumentsUrl;
        }
        context.filteredServiceDocumentsCategoriesList = [];
    }

    public onAutoUpload(event, fileuploadElement){
        let context = this;
        let fileup = <File>event.files[0];
        context.documentFile.push(fileup);
        fileuploadElement.clear();
    }

    public onRemove(fileObj, fileuploadElement){
        let context = this;
        let indexDelete = 0;
        context.documentFile.forEach((file, index) => {
            if(file.name == fileObj.name)
            {
                indexDelete = index;
            }
        });
        context.documentFile.splice(indexDelete, 1);
        fileuploadElement.uploadedFileCount = 0;
    }

    private setServiceIstanceFilter(){
        let context = this;
        context.serviceInstaceService.getServiceInstanceList(context.token, null, null, null).pipe(
            tap((res) => {
                if(res.data != null){
                    context.serviceInstancesFilter = res.data.map((service) => {
                        return {
                            id: service.id,
                            name: service.name
                        };
                    });
                }
        }), catchError(() => {return of();})).subscribe();
    }

    setServiceInstancesDropdown()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstanceList = res.data.map((service) => {
                            return {
                                id: service.id,
                                name: service.name
                            }
                        });
                    }
                }),
                catchError((res) => {
                    return of();
                })
            ).subscribe();
    }

    createDocument() 
    {
        this.document_new = {} as Document;
        this.document_new.status = '0';
        if(!this.userHasPermission('IS_CLIENT')){
            this.filteredServiceDocumentsCategoriesList = [];
        }
       
        if(this.userHasPermission('IS_CLIENT')){
            this.document_new.serviceInstance = sessionStorage.getItem("serviceInstanceId");
        }
        this.documentCreateModal = true;
    }

    public isFieldReadOnly(){
        return this.userHasPermission('IS_CLIENT');
    }

    public closeCreateDocument() {
        this.documentCreateModal = false;
    }

    public closeEditDocument() {
        this.documentEditModal = false;
    }

    public sanitize(url:string){
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    public editDocument(id:string){
        let context = this;
        context.setServiceDocumentsCategoriesDropdown();
        context.documentService.getDocument(context.token, id).pipe(
            tap((response) => {
                context.document_edit = {
                    id: response.data.id,
                    originalFileName: response.data.originalFileName,
                    fileName: response.data.fileName,
                    notes: response.data.notes,
                    description: response.data.description,
                    status: response.data.status,
                    documentsCategoryId: response.data.documentsCategoryId,
                    serviceInstanceId: response.data.serviceInstanceId,
                } as Document;

                context.filteredServiceDocumentsCategoriesList = context.serviceDocumentsCategoriesList.filter((category) => {
                    if(category.serviceInstanceId == response.data.serviceInstanceId){
                        return {
                            id: category.id,
                            name: category.name,
                            serviceInstanceId: category.serviceInstanceId
                        }
                    }
                });

                context.documentEditModal = true;
            }),catchError((error) => {
                context.messageService.add({
                    severity:"error",
                    summary:"Error",
                    detail:error.message ?? error.error.title
                });
                context.documentEditModal = false;
                return of();
        }),finalize(() => {})).subscribe();
    }

    public updateDocument(){
        let context = this;
        context.documentService.updateDocument(context.token, context.document_edit).pipe(
            tap((response) => {
                context.messageService.add({
                    severity:"success",
                    summary:"Success",
                    detail: response.message ?? "Success"
                });
                context.documentEditModal = false;
                context.getList(null);
            }),catchError((res) => {
                context.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: res.error?.message ?? res.error.title
                });
                return of();
        }),finalize(() => { context.documentEditModal = false; })).subscribe();
    }

    public saveDocument() {
        let context = this;

        let fileReader = new FileReader();
        fileReader.readAsDataURL(context.documentFile[0]);
        fileReader.onload = function () {
            var base64result = (<string>fileReader.result).split(',')[1];
            context.document_new.fileContent = base64result;
            context.document_new.originalFileName = context.documentFile[0].name;
            context.documentService.createDocument(context.token, context.document_new).pipe(
                tap((response) => {
                    context.messageService.add({
                        severity: "success",
                        summary: "Success",
                        detail: response.message ?? "Success"
                    });
                }),catchError((error) => {
                    context.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: error.message ?? error.error.title
                    });
                    return of();
                }),finalize(() => {
                    context.documentCreateModal = false;
                    context.getList(null);
            })).subscribe();
        };
    }

    public deleteDocument(id: string, filename: string){
        let context = this;
        context.confirmationService.confirm({
            message: "You are about to delete '" + filename + "'. Are you sure?",
            header: "Warning",
            icon: "pi pi-warning",
            acceptLabel: "Yes",
            rejectLabel: "No",
            accept: () => {
                context.documentService.deleteDocument(context.token, id).pipe(
                    tap((res) => {
                            context.messageService.add({
                                severity: "success",
                                summary: "Success",
                                detail: res.message ?? "Success"
                            });
                        }),catchError((res) => {
                            context.messageService.add({
                                severity: "error",
                                summary: "Error",
                                detail: res.error?.message ?? res.error.title
                            });
                            return of();
                        }),
                        finalize(() => {
                            context.getList(null);
                        })
                    ).subscribe();
            },reject: () => {
                return;
            }, rejectVisible: true });
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    private setServiceDocumentsCategoriesDropdown(){
        let context = this;
        
        context.documentCategoryService.getDocumentCategoriesList(context.token, null, null).pipe( tap((response) => {
            if(response.data != null){
                context.serviceDocumentsCategoriesList = response.data.map((category) => {
                    return {
                        id: category.id,
                        name: category.name,
                        serviceInstanceId: category.serviceInstanceId
                    }
                });
                context.serviceDocumentsCategoriesList.push({
                    id: '0',
                    name: 'None',
                    serviceInstanceId: '0'
                });

                if(context.userHasPermission('IS_CLIENT')){
                    context.filteredServiceDocumentsCategoriesList = context.serviceDocumentsCategoriesList;
                }

            }
        }),catchError((error) => {return of();})).subscribe();
    }

    public filterCategoriesByInstance(event){
        var context = this;
        event.value;

        context.filteredServiceDocumentsCategoriesList = context.serviceDocumentsCategoriesList.filter((category) => {
            if(category.serviceInstanceId == event.value){
                return {
                    id: category.id,
                    name: category.name,
                    serviceInstanceId: category.serviceInstanceId
                }
            }
        });
        var i = 1;
    }

    getDocumentCategoryTree(documentCategory){
        var categoryData = '';
        if(typeof documentCategory != 'undefined'){
            if(documentCategory.fatherDocumentCategory != null){
                categoryData = categoryData + ' ' + this.getDocumentCategoryTree(documentCategory.fatherDocumentCategory);
            }

            categoryData = categoryData + ',' +  documentCategory.name;
            
        }

        return categoryData;
    }

    getList(event:LazyLoadEvent) {
        let context = this;
        context.documentList = [];
        if(event != null){
            context.event_lazy_local = event;
        }

        let serviceInstanceId = context.dropdown_serviceIstances?.value ?? null;
        let filterStatus = context.dropdown_documentsStatus?.value ?? null;

        context.documentListLoading = true;
        context.documentService.getDocumentList(context.token, context.event_lazy_local, context.categoryId, serviceInstanceId, filterStatus).pipe(
            tap((res) => {
                    if(res.data != null){
                        let responseData = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + context.event_lazy_local.rows));
                        context.totalRecords = res.data.length;
                        responseData.forEach(async documentCategory => {

                            documentCategory.documentsCategory
                            var documentCategoryTree = '';

                            if(documentCategory.documentsCategory.length > 0){
                                var documentCategoryTreeData = documentCategory.documentsCategory[0].fatherDocumentCategories.reverse();
                            
                                for (let index = 0; index < documentCategoryTreeData.length; index++) {
                                    var element = documentCategoryTreeData[index];
                                    documentCategoryTree = documentCategoryTree + (index != 0 ? ' > ' : '') + element.name;
                                }

                                documentCategoryTree = (documentCategoryTree != "" ? documentCategoryTree + ' > ' : '') + documentCategory.documentsCategory[0].name;
                            }
                            

                            context.documentList.push({
                                id: documentCategory.id,
                                originalFileName: documentCategory.originalFileName,
                                fileName: documentCategory.fileName,
                                blobFilePath: documentCategory.blobFilePath,
                                mimeType: documentCategory.mimeType,
                                notes: documentCategory.notes,
                                description: documentCategory.description,
                                status: (documentCategory.status != null ? context.documentsStatus[documentCategory.status].label : ''),
                                fileContent: documentCategory.fileContent,
                                creationTime: documentCategory.creationTime,
                                documentCategory: documentCategoryTree,
                                documentsCategoryId:
                                    typeof documentCategory.documentsCategory !== "undefined" && documentCategory.documentsCategory !== null &&
                                    typeof documentCategory.documentsCategory[0] !== "undefined" && documentCategory.documentsCategory[0] !== null &&
                                    typeof documentCategory.documentsCategory[0].name !== "undefined" && documentCategory.documentsCategory[0].name !== null
                                        ? documentCategory.documentsCategory[0].name
                                        : "",
                                serviceInstance:
                                    typeof documentCategory.instance !== "undefined" && documentCategory.instance !== null &&
                                    typeof documentCategory.instance.name !== "undefined" && documentCategory.instance.name !== null
                                        ? documentCategory.instance.name
                                        : "",
                                serviceInstanceId: documentCategory.serviceInstanceId
                            });
                        });
                    }
                }),catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail:res.error?.message ?? res.error.title
                    })
                return of();
        }),finalize(() => {  context.documentListLoading = false;})).subscribe();
    }
}