import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppGlobals } from "src/app/app.globals";

export enum BaseRequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

@Injectable()
export class BaseService {
  private _httpClient: HttpClient;
  private _configurations: any = {};
  private _defaultHeaders: Object;
  private _services: any;

  constructor(
    protected httpClient: HttpClient,
  ) {
    this._configurations = AppGlobals.getAppConfigurations();
    this._httpClient = httpClient;
    this._services = this._configurations.services;
    this._defaultHeaders = this._services.defaultHeaders;
  }

  private getHost(name: string): string {
    let contextService = this;
    let hosts = contextService._services.hosts;

    if (name == null || name == undefined) {
      return hosts[contextService._services.defaultHost];
    }

    let host = hosts[name]
      ? hosts[name]
      : hosts[contextService._services.defaultHost];

    return host;
  }

  callServiceFormData<T>(
    method:BaseRequestMethod,
    serviceName: string,
    formData: FormData,
    token?:string,
    contentType = "multipart/form-data",
    host: any = null
  ){
    let contextService = this;
    let serviceHost = contextService.getHost(host);
    let url = serviceHost + serviceName;
    let headers = {
      "Authorization" : 'Bearer '+token
    };

    let handlerService = null;

    Object.keys(contextService._defaultHeaders).forEach((header) => {
      headers[header] = contextService._defaultHeaders[header];
    });

    switch (method) {
      case BaseRequestMethod.POST:
        handlerService = contextService._httpClient.post<T>(url, formData, {
          headers: new HttpHeaders(headers),
        });
        break;
      case BaseRequestMethod.PUT:
        handlerService = contextService._httpClient.put<T>(url, formData, {
          headers: new HttpHeaders(headers),
        });
        break;
    }

    return handlerService;
  }

  callService<T>(
    method: BaseRequestMethod,
    serviceName: string,
    token?:string,
    data?: any,
    contentType = "application/json",
    host: any = null,
    ovverideContext = true
  ) {
    let contextService = this;
    let serviceHost = contextService.getHost(host);
    let url = serviceHost + serviceName;
    let body = { ...contextService._services.requestBody };
    let headers = {};
    if(serviceName != 'users/authenticate')
    {
      headers = {
        Accept: "application/json",
        "Content-Type":
          typeof contentType == "undefined" ||
          (typeof contentType != "undefined" && contentType == null)
            ? "application/json"
            : contentType,
          "Authorization" : 'Bearer '+token
      };
    }
    else
    {
      headers = {
        Accept: "application/json",
        "Content-Type":
          typeof contentType == "undefined" ||
          (typeof contentType != "undefined" && contentType == null)
            ? "application/json"
            : contentType,
      };
    }
    
    if (data != null) {
      body.data = data;
    }

    let handlerService = null;

    Object.keys(contextService._defaultHeaders).forEach((header) => {
      headers[header] = contextService._defaultHeaders[header];
    });

    switch (method) {
      case BaseRequestMethod.GET:
        handlerService = contextService._httpClient.get<T>(url, {
          headers: new HttpHeaders(headers),
        });
        break;
      case BaseRequestMethod.POST:
        handlerService = contextService._httpClient.post<T>(url, body, {
          headers: new HttpHeaders(headers),
        });
        break;
      case BaseRequestMethod.PUT:
        handlerService = contextService._httpClient.put<T>(url, body, {
          headers: new HttpHeaders(headers),
        });
        break;
      case BaseRequestMethod.DELETE:
        handlerService = contextService._httpClient.request<T>("delete", url, {
          headers: new HttpHeaders(headers),
          body: body,
        });
        break;
    }

    return handlerService;
  }
}
