<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
    <div class="card">
        <p-toolbar styleClass="p-mb-4">
            <div class="p-toolbar-group-left"></div>
            <div class="p-toolbar-group-right">
                <button pButton label="Create Group" *ngIf="userHasPermission('CAN_ADD_NEW_USER_GROUP')" (click)="createGroup()" class="p-button-oris"></button>
            </div>
        </p-toolbar>
        <p-table #dtGroups [value]="groupsList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
            [globalFilterFields]="['name', 'serviceInstance']"
            (onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
            [rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="groupsListLoading">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    Groups List
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dtGroups.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Name
                            <div>
                            <p-sortIcon field="name"></p-sortIcon>
                            </div>
                        </div>
                    </th>
                    <th pSortableColumn="serviceInstance">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Service Instance
                            <div>
                            <p-sortIcon field="serviceInstance"></p-sortIcon>
                            </div>
                        </div>
                    </th>
                    <th style="width: 8rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-group>
                <tr class="p-selectable-row">
                    <td>
                        {{group.name}}
                    </td>
                    <td>
                        {{group.serviceInstance}}
                    </td>
                    <td>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_EDIT_USER_GROUP_DATA')" title="Edit" icon="pi pi-pencil"
							class="p-button-rounded p-button-text" (click)="editGroup(group.id)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_VIEW_PERMISSIONS_LIST')" title="Permissions" icon="pi pi-key"
                            class="p-button-rounded p-button-text" (click)="permissionsGroup(group.id)"></button>
						<button pButton type="button" *ngIf="userHasPermission('CAN_DELETE_USERS_GROUPS')" title="Delete" icon="pi pi-trash"
                            class="p-button-rounded p-button-text" (click)="deleteGroup(group.id, group.name)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td>No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<form #createGroupForm="ngForm" (ngSubmit)="createGroupForm.form.valid && saveGroup()">
    <p-dialog header="New Group" [(visible)]="groupCreateModal" [modal]="true" [style]="{width: '660px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <div class="p-formgroup-inline">
            <div class="p-col-6">
				<label for="groupNew_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="group_new.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serviceInstances" [baseZIndex]="10001" required optionValue="id" #service_instance='ngModel'>
				</p-dropdown>
				<div *ngIf="createGroupForm.submitted && service_instance.invalid" class="error-login-info">
					<small *ngIf="service_instance.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="groupNew_name">Name</label>
                <input type="text" pInputText id="groupNew_name" name="groupNew_name" [(ngModel)]="group_new.name"
                    #name='ngModel' required />
                <div *ngIf="createGroupForm.submitted && name.invalid" class="error-login-info">
                    <small *ngIf="name.errors?.required" class="p-error">Enter name</small>
                </div>
            </div>
            <div class="p-col-6">
                <label for="groupNew_description">Description</label>
                <input type="text" pInputText id="groupNew_description" name="groupNew_description"
                    [(ngModel)]="group_new.description" #surname='ngModel'/>
            </div>
        </div>
        <br />
        <br />
        <br />
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" type="button" class="p-button-danger"
                (click)="closeCreateGroupModal()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
        </ng-template>
    </p-dialog>
</form>

<form #editGroupForm="ngForm" (ngSubmit)="editGroupForm.form.valid && updateGroup()">
    <p-dialog header="Edit Group" [(visible)]="groupEditModal" [modal]="true" [style]="{width: '660px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <div class="p-formgroup-inline">
            <div class="p-col-6">
				<label for="groupEdit_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="group_edit.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="groupEdit_serviceInstance" [baseZIndex]="10001" required optionValue="id" #edit_service_instance='ngModel'>
				</p-dropdown>
				<div *ngIf="editGroupForm.submitted && edit_service_instance.invalid" class="error-login-info">
					<small *ngIf="edit_service_instance.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="groupEdit_name">Name</label>
                <input type="text" pInputText id="groupEdit_name" name="groupEdit_name" [(ngModel)]="group_edit.name"
                    #edit_name='ngModel' required />
                <div *ngIf="editGroupForm.submitted && edit_name.invalid" class="error-login-info">
                    <small *ngIf="edit_name.errors?.required" class="p-error">Enter name</small>
                </div>
            </div>
            <div class="p-col-6">
                <label for="groupEdit_description">Description</label>
                <input type="text" pInputText id="groupEdit_description" name="groupEdit_description"
                    [(ngModel)]="group_edit.description" #surname='ngModel'/>
            </div>
        </div>
        <br />
        <br />
        <br />
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" type="button" class="p-button-danger"
                (click)="closeEditGroupModal()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
        </ng-template>
    </p-dialog>
</form>

<form #formPermissions="ngForm">
    <input type="text" id="groupPermission_id" name="groupPermission_id" value="{{groupPermissionId}}" hidden>
    <p-dialog header="Group Permissions" [(visible)]="permissionModal" [modal]="true" [style]="{width:'500px'}" styleClass="p-fluid" [baseZIndex]="10000"
                [draggable]="false" [resizable]="false">
                <ng-template pTemplate="body">
                    <p-tree [value]="permissionsNodes" selectionMode="checkbox" [(selection)]="permissionsNodesSelected"
                    (onNodeSelect)="selectNode($event)" (onNodeUnselect)="unselectNode($event)"></p-tree>
                </ng-template>
                <ng-template pTemplate="footer">
                    <br/>
                    <br/>
                    <button pButton pRipple label="Close" icon="pi pi-times" type="button" class="p-button-danger"
                        (click)="closePermissionsGroup()"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"
                        (click)="savePermissions()"></button>
                </ng-template>
    </p-dialog>
</form>