import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { stringify } from 'querystring';
import { of } from 'rxjs';
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppGlobals } from 'src/app/app.globals';
import { AppBreadcrumbService } from 'src/app/core/breadcumb/app.breadcrumb.service';
import { ServiceInstance } from '../instances/domain/instance';
import { InstanceService } from '../instances/service/instanceservice';
import { Customer } from './domain/customer';
import { CustomerService } from './service/customerservice';

@Component({
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss']
})


export class CustomerComponent {
    private token: string;
    public customer_new: Customer = {};
    public customer_edit: Customer = {};
    public customerList: Customer[];
    public serviceInstancesFilter: ServiceInstance[];
    public customerCreateModal: boolean = false;
    public customerEditModal: boolean = false;
    public customerListLoading: boolean = true;
    public warningModal: boolean;
    public totalRecords: number;
    event_lazy_local:LazyLoadEvent;
    itemsPerPage: number = 25;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService,
        private customerService: CustomerService) {
        this.token = sessionStorage.getItem('alkenium_Token').toString();

        this.breadcrumbService.setItems([
            AppGlobals.setBreadCrumb('Customers', '/customers', AppGlobals.userHasPermission("IS_CLIENT"))
        ]);
        
        this.event_lazy_local = {} as LazyLoadEvent;
        this.setServiceIstanceFilter();
    }

    createCustomer() 
    {
        this.customer_new = {} as Customer;
        this.customerCreateModal = true;
    }

    setServiceIstanceFilter()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstancesFilter = res.data.map((service) => {
                            return {
                            id: service.id,
                            name: service.name
                        }});
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    editCustomer(id:string){
        this.customerService.getCustomer(this.token, id)
            .pipe(
                tap((res) => {
                    this.customer_edit = {
                        id: res.data.id,
                        companyName: res.data.companyName,
                        vatNumber: res.data.vatNumber,
                        fiscalCode: res.data.fiscalCode ?? null,
                        sdiCode: res.data.sdiCode ?? null,
                        erpReferenceCode: res.data.erpReferenceCode ?? null,
                        emailAddress: res.data.emailAddress ?? null,
                        pecEmailAddress: res.data.pecEmailAddress ?? null
                    } as Customer;
                    this.customerEditModal = true;
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity:"error",
                        summary:"Error",
                        detail:res.error?.message ?? res.error.title
                    });
                    this.customerEditModal = false;
                    return of();
                }),
                finalize(() => {
                })
            )
            .subscribe();
    }

    closeCreateCustomer() {
        this.customerCreateModal = false;
    }

    closeEditCustomer()
    {
        this.customerEditModal = false;
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    updateCustomer()
    {
        this.customerService.updateCustomer(this.token, this.customer_edit)
            .pipe(
                tap((res) => {
                    this.messageService.add({
                        severity:"success",
                        summary:"Success",
                        detail: res.message ?? "Success"
                    });
                    this.closeEditCustomer();
                    this.getList(null);
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: res.error?.message ?? res.error.title
                    });
                    return of();
                }),
                finalize(() => {
                })
            )
            .subscribe();
    }

    saveCustomer() {
        this.customerService.createCustomer(this.token, this.customer_new)
            .pipe(
                tap((res) => {
                    this.messageService.add({
                        severity: "success",
                        summary: "Success",
                        detail: res.message ?? "Success"
                    });
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: res.error?.message ?? res.error.title
                    });
                    return of();
                }),
                finalize(() => {
                    this.closeCreateCustomer();
                    this.getList(null);
                })
            )
            .subscribe();
    }

    deleteCustomer(id: string, companyName:string) {
        this.confirmationService.confirm({
            message: "You are about to delete '"+companyName+"'. Are you sure?",
            header: "Warning",
            icon: "pi pi-warning",
            acceptLabel: "Yes",
            rejectLabel: "No",
            accept: () => {
                this.customerService
                    .deleteCustomer(this.token, id)
                    .pipe(
                        tap((res) => {
                            this.messageService.add({
                                severity: "success",
                                summary: "Success",
                                detail: res.message ?? "Success"
                            });
                        }),
                        catchError((res) => {
                            this.messageService.add({
                                severity: "error",
                                summary: "Error",
                                detail: res.error?.message ?? res.error.title
                            });
                            return of();
                        }),
                        finalize(() => {
                            this.getList(null);
                        })
                    )
                    .subscribe();
            },
            reject: () => {
                return;
            },
            rejectVisible: true
        }
        );
    }

    getList(event:LazyLoadEvent) {
        if(event != null)
            this.event_lazy_local = event;
        
        let serviceIstanceId = this.dropdown_serviceIstances?.value ?? null;
        this.customerListLoading = true;

        this.customerService.getCustomerList(this.token, this.event_lazy_local, serviceIstanceId)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        let test = res.data.slice(this.event_lazy_local.first, (this.event_lazy_local.first + this.event_lazy_local.rows));
                        this.totalRecords = res.data.length;
                        this.customerList = test.map((customer) => {
                            return {
                                id: customer.id,
                                companyName: customer.companyName,
                                guid:customer.guid,
                                vatNumber: customer.vatNumber,
                                fiscalCode: customer.fiscalCode,
                                emailAddress: customer.emailAddress,
                                sdiCode: customer.sdiCode,
                                pecEmailAddress: customer.pecEmailAddress,
                                erpReferenceCode: customer.erpReferenceCode,
                                instances: customer.instances
                            }
                        });
                        this.customerListLoading = false;
                    }
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail:res.error?.message ?? res.error.title
                    })
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }
}