import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, LazyLoadEvent, MessageService } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { of } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppGlobals } from "src/app/app.globals";
import { AppBreadcrumbService } from "src/app/core/breadcumb/app.breadcrumb.service";
import { CustomerService } from "../customers/service/customerservice";
import { ServiceInstance } from "../instances/domain/instance";
import { InstanceService } from "../instances/service/instanceservice";
import { CollectionPlan } from "./domain/collectionPlan";
import { CollectionPlanDetail } from "./domain/collectionPlanDetail";
import { CollectionPlanHeaderItem } from "./domain/collectionPlanHeaderItem";
import { CollectionPlanService } from "../collectionPlans/service/collectionPlan.service";
import { CollectionPlanDetailsService } from "./service/collectionPlanDetails.service";
import { CollectionPlanVersionNumberFilter } from "./domain/collectionPlanVersionNumberFilter";
import { ExportFileType } from "src/app/enum/exportFileType.enum";
import { ExportOptions } from "../instances/domain/exportOptions";

@Component({
    templateUrl: "./collectionPlanDetails.component.html",
    styleUrls: ["./collectionPlanDetails.component.scss"],
})


export class collectionPlanDetailsComponent {
    private token: string;
    public collectionPlanList: CollectionPlanDetail[];
    public collectionPlanItemsList: CollectionPlanHeaderItem[];
    public clientsList: Document[] = [];
    public serviceInstanceList: ServiceInstance[];
    public serviceInstancesFilter: ServiceInstance[];
    public versionNumberFilter: CollectionPlanVersionNumberFilter[];
    public versionNumberFilterSelected: CollectionPlanVersionNumberFilter
    public collectionPlanListLoading: boolean = true;
    public collectionPlanItemsListLoading: boolean = true;
    public collectionPlanExportLoading: boolean = false;
    public warningModal: boolean;
    public totalRecords: number;
    public totalItemsRecords: number;
    public collectionPlanDetailId: string;
    public collectionPlanData: CollectionPlan;
    public firstLoading: boolean = true;
    public currentVersionNumber: number;
    public exportFileType = ExportFileType;

    public collectionPlan_export_type: ExportFileType;
    public exportOptionList: ExportOptions[] = [
        {
            option : "Simple (Line Planning)",
            value : false
        },
        {
        option : "Full (Line Planning + Products)",
        value : true
    }];

    public selectedExportValue: boolean = false;
    public exportCollectionPlanModal: boolean = false;

    event_lazy_local: LazyLoadEvent;
    itemsPerPage: number = 25;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private collectionPlanService: CollectionPlanService,
        private collectionPlanDetailsService: CollectionPlanDetailsService,
        private router:Router,
        private customerService: CustomerService) {
        let context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([AppGlobals.setBreadCrumb('Collection Plans', '/collectionplans', AppGlobals.userHasPermission("IS_CLIENT"))]);
        context.event_lazy_local = {} as LazyLoadEvent;
        this.collectionPlanDetailId = this.route.snapshot.paramMap.get('id');
        this.getCollectionPlanData(this.collectionPlanDetailId);
    }

    setServiceIstanceFilter()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstancesFilter = res.data.map((service) => {
                            return {
                            id: service.id,
                            name: service.name
                        }});
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    setVersionNumberFilterDropDown()
    {
        let context = this;
        context.collectionPlanDetailsService.getCollectionPlanVersionList(context.token, context.collectionPlanData.header.id)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        context.versionNumberFilter = res.data.map((versionNumber: CollectionPlan) => {
                            return {
                                id: versionNumber.versionNumber,
                                name: `N.${versionNumber.versionNumber} of ${
                                    context.setFormatDateTime(new Date(versionNumber.creationTime))
                                }${
                                    context.currentVersionNumber ==
                                    versionNumber.versionNumber
                                    ? " (current)"
                                    : ""
                                }`,
                                filterValue: versionNumber.id,
                                };
                        });
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    private setClientsDropdown(){
        let context = this;
        context.customerService.getCustomerList(context.token, null, null) .pipe(
            tap((response) => {
                if(response.data != null){
                    context.clientsList = response.data.map((clients) => {
                        return {
                            id: clients.id,
                            name: clients.companyName
                        }
                });
            }
        }),catchError((error) => {return of();})).subscribe();
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    private setServiceInstancesDropdown()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstanceList = res.data.map((service) => {
                            return {
                                id: service.id,
                                name: service.name
                            }
                        });
                    }
                }),
                catchError((res) => {
                    return of();
                })
            ).subscribe();
    }

    public handleVersionNumberFilter(version: number) {
        let context = this;

        context.versionNumberFilterSelected = context.versionNumberFilter.find((item: CollectionPlanVersionNumberFilter) =>  item.id == version);

        context.getCollectionPlanData(context.collectionPlanDetailId, context.versionNumberFilterSelected.filterValue);
    }

    public getItemsList() {
        let context = this;

        context.collectionPlanItemsListLoading = true;

        if(!!context.collectionPlanData && !!context.collectionPlanData.header && !!context.collectionPlanData.header.items){
            context.totalItemsRecords = context.collectionPlanData.header.items.length;
            context.collectionPlanItemsList = context.collectionPlanData.header.items;
        }

        context.collectionPlanItemsListLoading = false;
    }

    public getDetailsList() {
        let context = this;

        context.collectionPlanListLoading = true;

        if(!!context.collectionPlanData && !!context.collectionPlanData.details){
            context.totalItemsRecords = context.collectionPlanData.details.length;

            context.collectionPlanList = context.collectionPlanData.details.map((collectionPlan) => {
                return {
                    season: collectionPlan.season,
                    productCategory: collectionPlan.productCategory,
                    project: collectionPlan.project,
                    collection: collectionPlan.collection,
                    line: collectionPlan.line,
                    lpCode: collectionPlan.lpCode,
                };
            });
        }

        context.collectionPlanListLoading = false;
    }

    public getExtensionFile(typeExport: ExportFileType) {
        let context = this;
        if (typeExport === context.exportFileType.EXCEL) {
            return "xlsx";
        } else if (typeExport === context.exportFileType.PDF) {
            return "pdf";
        } else {
            return "xlsx";
        }
    }

    public exportFile(content: string, name: string, typeExport: ExportFileType, versionNumber: number) {
        let context = this;
        const linkSource = "data:application/octet-stream;base64," + content;
        const downloadLink = document.createElement("a");
        const fileName = "PIANO COLLEZIONE ALKENIUM " + name.replace(/[^a-zA-Z0-9 ]/g, '') + "_" + versionNumber + "." + context.getExtensionFile(typeExport);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public exportCollectionPlan() {
        let context = this,
            versionId =
                typeof context.versionNumberFilterSelected !== "undefined" &&
                typeof context.versionNumberFilterSelected.filterValue !==
                "undefined"
                    ? context.versionNumberFilterSelected.filterValue
                    : undefined,
            versionFile =
                typeof context.versionNumberFilterSelected !== "undefined" &&
                typeof context.versionNumberFilterSelected.filterValue !== "undefined"
                    ? context.versionNumberFilterSelected.id
                    : context.collectionPlanData.versionNumber

        context.collectionPlanExportLoading = true;
        context.exportCollectionPlanModal = false;

        context.collectionPlanService.exportCollectionPlan(context.token, null, context.collectionPlanDetailId, context.collectionPlan_export_type, versionId, context.selectedExportValue).pipe(
                tap((response) => {
                    if (typeof response.data !== 'undefined' && typeof response.data.content !== 'undefined'  && response.data.content !== '') {
                        context.exportFile(response.data.content, context.collectionPlanData.header.name, context.collectionPlan_export_type, versionFile);
                        context.messageService.add({
                            severity: "success",
                            summary: "Success",
                            detail: response.message ?? "Exported the file successfully"
                        });
                    } else {
                        context.messageService.add({
                          severity: "error",
                          summary: "Error",
                          detail: "Could not export the file",
                        });
                        return of();
                    }
                }),catchError((error) => {
                    context.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: error.message ?? error.error.title
                    });
                    return of();
                }),finalize(() => {
                    context.collectionPlanExportLoading = false;
                })).subscribe();
    }

    getCollectionPlanData(collectionId:string, collectionVersionId?: string)
    {
        let context = this;
        context.collectionPlanDetailsService.getCollectionPlan(context.token, null, null, collectionId, collectionVersionId)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        context.collectionPlanData = res.data;

                        if (context.firstLoading === true && typeof context.currentVersionNumber === "undefined") {
                            context.firstLoading = false;
                            context.currentVersionNumber = res.data.versionNumber;
                        }
                    }
                }),
                catchError(() => {
                    return of();
                }),
                finalize(() => {
                    context.getItemsList();
                    context.getDetailsList();
                    context.setVersionNumberFilterDropDown();
                })
            )
            .subscribe();
    }

    private padTo2Digits(num: any) {
        return num.toString().padStart(2, "0");
    }

    private setFormatDateTime(inputDate: Date) {
        let context = this;
        return (
          [
            context.padTo2Digits(inputDate.getDate()),
            context.padTo2Digits(inputDate.getMonth() + 1),
            inputDate.getFullYear(),
          ].join("/") +
          " " +
          [
            context.padTo2Digits(inputDate.getHours()),
            context.padTo2Digits(inputDate.getMinutes()),
          ].join(":")
        );
    }

    public openExportCollectionPlanModal(typeExport: ExportFileType) {
        let context = this;

        context.collectionPlan_export_type = typeExport;

        context.exportCollectionPlanModal = true;
    }

    public closeExportCollectionPlanModal() {
        this.exportCollectionPlanModal = false;
    }
}