import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GalleriaModule } from 'primeng/galleria';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToolbarModule } from 'primeng/toolbar';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import {DatePipe} from "@angular/common";
import { AccordionModule } from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { RadioButton, RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { DashboardService } from './service/dashboardservice';
import { DashboardComponent } from "./dashboard.component";
import { ChartModule } from 'primeng/chart';
import {TimelineModule} from 'primeng/timeline';

@NgModule({
  declarations: [
    DashboardComponent
  ],
  providers:[
    DashboardService,
    DatePipe,
    MessageService,
    ConfirmationService,
  ],
  imports: [
    CommonModule, BrowserModule, BrowserAnimationsModule, ToastModule, SelectButtonModule,
    FormsModule, ReactiveFormsModule, DialogModule, ToolbarModule,
    TableModule, FileUploadModule, ConfirmDialogModule,
    HttpClientModule, GalleriaModule, InputTextareaModule,
    ButtonModule, TabViewModule, AccordionModule,
    TabMenuModule, InputTextModule, CheckboxModule,
    CalendarModule, DropdownModule, RadioButtonModule,
    ScrollPanelModule, ProgressSpinnerModule, ChartModule, TimelineModule
  ]
})
export class DashboardModule { }

export function DashboardEntryPoint()
{
    return DashboardModule;
}