import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthenticationUtils } from "../utils/auth-utils";

@Injectable()
export class AuthenticationGuard implements CanActivate{
    private _authUtils: AuthenticationUtils;

    constructor(authUtils: AuthenticationUtils) {
        this._authUtils = authUtils;
      }
    
      canActivate() {
        this._authUtils.manageAuthorized();
        return true;
      }
}