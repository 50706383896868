import {Component, OnInit} from '@angular/core';
import {AppBreadcrumbService} from 'src/app/core/breadcumb/app.breadcrumb.service';

@Component({
    templateUrl: './chartsdemo.component.html'
})
export class ChartsDemoComponent implements OnInit {

    lineData: any;

    lineBarOptions: any;

    barData: any;

    pieData: any;

    pieDoughnutOptions: any;

    polarData: any;

    radarData: any;

    radarPolarOptions: any;

    constructor(private breadcrumbService: AppBreadcrumbService) {
        this.breadcrumbService.setItems([
            { label: 'UI Kit' },
            { label: 'Chart', routerLink: ['/uikit/charts'] }
        ]);
    }

    ngOnInit() {
        this.lineData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    backgroundColor: 'rgb(255, 205, 86)',
                    borderColor: 'rgb(255, 205, 86)'
                },
                {
                    label: 'Second Dataset',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    backgroundColor: 'rgb(75, 192, 192)',
                    borderColor: 'rgb(75, 192, 192)'
                }
            ]
        };

        this.barData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'My First dataset',
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgb(255, 99, 132)',
                    data: [65, 59, 80, 81, 56, 55, 40]
                },
                {
                    label: 'My Second dataset',
                    backgroundColor: 'rgb(54, 162, 235)',
                    borderColor: 'rgb(54, 162, 235)',
                    data: [28, 48, 40, 19, 86, 27, 90]
                }
            ]
        };

        this.lineBarOptions = {
            legend: {
                display: true,
                labels: {
                    fontColor: '#A0A7B5'
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        fontColor: '#A0A7B5'
                    },
                    gridLines: {
                        color:  'rgba(160, 167, 181, .3)',
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontColor: '#A0A7B5'
                    },
                    gridLines: {
                        color:  'rgba(160, 167, 181, .3)',
                    }
                }],
            }
        };

        this.pieData = {
            labels: ['A', 'B', 'C'],
            datasets: [
                {
                    data: [540, 325, 702, 421],
                    backgroundColor: [
                        'rgb(54, 162, 235)',
                        'rgb(255, 99, 132)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)'
                    ],
                    borderColor: '#A0A7B5'
                }]
        };

        this.pieDoughnutOptions = {
            legend: {
                display: true,
                labels: {
                    fontColor: '#A0A7B5'
                }
            }
        };

        this.radarData = {
            labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
            datasets: [
                {
                    label: 'My First dataset',
                    backgroundColor: 'rgba(54, 162, 235,0.2)',
                    borderColor: 'rgba(54, 162, 235,1)',
                    pointBackgroundColor: 'rgba(54, 162, 235,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(54, 162, 235,1)',
                    data: [65, 59, 90, 81, 56, 55, 40]
                },
                {
                    label: 'My Second dataset',
                    backgroundColor: 'rgba(255, 99, 132,0.2)',
                    borderColor: 'rgba(255, 99, 132,1)',
                    pointBackgroundColor: 'rgba(255, 99, 132,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(255, 99, 132,1)',
                    data: [28, 48, 40, 19, 96, 27, 100]
                }
            ]
        };

        this.polarData = {
            datasets: [{
                data: [
                    11,
                    16,
                    7,
                    3
                ],
                backgroundColor: [
                    'rgb(54, 162, 235)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 205, 86)',
                    'rgb(75, 192, 192)'
                ],
                label: 'My dataset'
            }],
            labels: [
                'Blue',
                'Purple',
                'Orange',
                'Green'
            ]
        };

        this.radarPolarOptions = {
            legend: {
                display: true,
                labels: {
                    fontColor: '#A0A7B5'
                }
            },
            scale: {
                gridLines: {
                    color: '#A0A7B5'
                },
                ticks: {
                    backdropColor: 'transparent'
                }
            },
        };
    }
}
