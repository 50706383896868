import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';

@Injectable()
export class DashboardService {

    constructor(private http: HttpClient, private baseservice: BaseService) {}

}