import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AccordionModule } from "primeng/accordion";
import { ConfirmationService, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { RadioButtonModule } from "primeng/radiobutton";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SelectButtonModule } from "primeng/selectbutton";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { ToolbarModule } from "primeng/toolbar";
import { collectionPlanDetailsComponent } from "./collectionPlanDetails.component";
import { CollectionPlanDetailsService } from "./service/collectionPlanDetails.service";

@NgModule({
    declarations: [collectionPlanDetailsComponent],
    providers: [DatePipe, ConfirmationService, MessageService, CollectionPlanDetailsService],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ToastModule,
        CalendarModule,
        SelectButtonModule,
        FormsModule,
        ReactiveFormsModule,
        DialogModule,
        ToolbarModule,
        TableModule,
        ConfirmDialogModule,
        HttpClientModule,
        InputTextareaModule,
        ButtonModule,
        TabViewModule,
        AccordionModule,
        TabMenuModule,
        InputTextModule,
        CheckboxModule,
        DropdownModule,
        RadioButtonModule,
        ScrollPanelModule,
        ProgressSpinnerModule,
    ],
})
export class CollectionPlanDetailsModule {}
