import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';
import { Supplier } from '../domain/supplier';

@Injectable()
export class SupplierService {

    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getSupplier(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.GET, "suppliers/supplier/"+ id, token, {});
    }

    getSupplierList(token:string, event:LazyLoadEvent):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "suppliers", token, {
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    }

    createSupplier(token:string, supplier:Supplier):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "suppliers/create", token,
            {
                companyName: supplier.companyName,
                vatNumber: supplier.vatNumber,
                emailAddress:
                    typeof supplier.emailAddress !== "undefined" && supplier.emailAddress !== null && supplier.emailAddress.trim() !== "" 
                    ? supplier.emailAddress : null,
                fiscalCode: supplier.fiscalCode ?? null,
                pecEmailAddress:
                    typeof supplier.pecEmailAddress !== "undefined" && supplier.pecEmailAddress !== null && supplier.pecEmailAddress.trim() !== "" 
                    ? supplier.pecEmailAddress : null,
                phoneNumber: supplier.phoneNumber ?? null,
                webSiteAddress: 
                    typeof supplier.webSiteAddress !== "undefined" && supplier.webSiteAddress !== null && supplier.webSiteAddress.trim() !== "" 
                    ? supplier.webSiteAddress 
                    : null,
                address: supplier.address ?? null,
                zipCode: supplier.zipCode ?? null,
                district: supplier.district ?? null,
                country: supplier.country ?? null,
                city: supplier.city ?? null,
                companyDescription: supplier.companyDescription ?? null
            });
    }

    updateSupplier(token:string, supplier:Supplier):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.PUT, "suppliers/update", token,
            {
                id: supplier.id,
                companyName: supplier.companyName,
                vatNumber: supplier.vatNumber,
                emailAddress:
                    typeof supplier.emailAddress !== "undefined" && supplier.emailAddress !== null && supplier.emailAddress.trim() !== "" 
                    ? supplier.emailAddress : null,
                fiscalCode: supplier.fiscalCode ?? null,
                pecEmailAddress:
                    typeof supplier.pecEmailAddress !== "undefined" && supplier.pecEmailAddress !== null && supplier.pecEmailAddress.trim() !== "" 
                    ? supplier.pecEmailAddress : null,
                phoneNumber: supplier.phoneNumber ?? null,
                webSiteAddress: 
                    typeof supplier.webSiteAddress !== "undefined" && supplier.webSiteAddress !== null && supplier.webSiteAddress.trim() !== "" 
                    ? supplier.webSiteAddress 
                    : null,
                address: supplier.address ?? null,
                zipCode: supplier.zipCode ?? null,
                district: supplier.district ?? null,
                country: supplier.country ?? null,
                city: supplier.city ?? null,
                companyDescription: supplier.companyDescription ?? null
            });
    }

    deleteSupplier(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.DELETE, "suppliers/supplier/"+id, token, {});
    }
}