<div class="p-col-12">
    <div *ngIf="collectionPlanExportLoading">
        <div class="p-fl-container"></div>
        <div class="p-d-flex p-fl-spinner">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </div>
    <div class="card" *ngIf="collectionPlanData !== undefined">
        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
            <div class="table-header-title"><h3 style="color: #222;">Collection Details</h3></div>
            <div *ngIf="userHasPermission('CAN_EXPORT_COLLECTIONPLAN')">
                <button pButton pRipple label="Export Excel" icon="pi pi-file-excel" type="button" class="p-button-oris button-export"
                    (click)="openExportCollectionPlanModal(exportFileType.EXCEL)"></button>
                <button pButton pRipple label="Export PDF" icon="pi pi-file-pdf" type="button" class="p-button-oris button-export"
                    (click)="openExportCollectionPlanModal(exportFileType.PDF)"></button>
            </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row p-mb-4">
            <div style="margin-right: 2rem;">
                <label for="filter_collectionPlanVersion">Version Number</label>
                <br />
                <p-dropdown [options]="versionNumberFilter" [(ngModel)]="collectionPlanData.versionNumber"
                    placeholder="Select version number" optionLabel="name" optionValue="id"
                    name="collectionPlanVersionNumber" [showClear]="false" [style]="{'min-width':'100%'}"
                    (onChange)="handleVersionNumberFilter($event.value)" #dropdown_serviceIstances='ngModel'></p-dropdown>
            </div>
        </div>
        <div style="background-color: #ac965f;color:#fff;border-radius: 5px;">
            <div class="p-formgroup-inline" >
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Name:</b> {{collectionPlanData.header.name}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Transaction Id:</b> {{collectionPlanData.header.transactionId}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Project:</b> {{collectionPlanData.header.project}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Season:</b> {{collectionPlanData.header.season}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Collection:</b> {{collectionPlanData.header.collection}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #948150;">
                <div class="p-col-12"><b>User:</b> {{collectionPlanData.header.user}}</div>
            </div>
        </div>
    </div>
    <div class="card">
        <p-table #dtCollectionPlan [value]="collectionPlanItemsList" dataKey="id" styleClass="p-datatable-oris"
            [rowHover]="true" [globalFilterFields]="['family', 'productCategory', 'line', 'management']"
            [totalRecords]="(totalItemsRecords)" [rows]="(itemsPerPage)" [loading]="collectionPlanItemsListLoading"
            [paginator]="true" [filterDelay]="0">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    Collection items list
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="productCategory">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Product
                            <p-sortIcon field="productCategory"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="nr_request">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Paper<br>templates
                            <p-sortIcon field="nr_request"></p-sortIcon>
                        </div>
                    </th>

                    <th pSortableColumn="nr_Sample">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Fabrics
                            <p-sortIcon field="nr_Sample"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="nr_Palcehoder">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Hanging<br>clothes
                            <p-sortIcon field="nr_Palcehoder"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="nr_Prototype">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Model<br>Openings
                            <p-sortIcon field="nr_Prototype"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="nr_Finisched">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Codes
                            <p-sortIcon field="nr_Finisched"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Tot. codes<br>to cost.
                            <p-sortIcon field=""></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="lowPrice">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Low<br>price
                            <p-sortIcon field="lowPrice"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="mediumPrice">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Medium<br>price
                            <p-sortIcon field="mediumPrice"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="hightPrice">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            High<br>price
                            <p-sortIcon field="hightPrice"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="extraPrice">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Extra<br>price
                            <p-sortIcon field="extraPrice"></p-sortIcon>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-collectionPlan let-expanded="expanded">
                <tr class="p-selectable-row">
                    <td>
                        {{collectionPlan.productCategory}}
                    </td>
                    <td>
                        {{collectionPlan.nr_request}}
                    </td>
                    <td>
                        {{collectionPlan.nr_Sample}}
                    </td>
                    <td>
                        {{collectionPlan.nr_Palcehoder}}
                    </td>
                    <td>
                        {{collectionPlan.nr_Prototype}}
                    </td>
                    <td>
                        {{collectionPlan.nr_Finisched}}
                    </td>
                    <td>
                        0
                    </td>
                    <td>
                        {{collectionPlan.lowPrice}} &euro;
                    </td>
                    <td>
                        {{collectionPlan.mediumPrice}} &euro;
                    </td>
                    <td>
                        {{collectionPlan.hightPrice}} &euro;
                    </td>
                    <td>
                        {{collectionPlan.extraPrice}} &euro;
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td>No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="card">
        <p-table #dtCollectionPlan [value]="collectionPlanList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
            [globalFilterFields]="['collection', 'lpCode', 'project', 'season', 'productCategory', 'line']"
            [totalRecords]="(totalRecords)" [rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="collectionPlanListLoading">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    Collection details list

                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="collection">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Collection
                            <p-sortIcon field="collection"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="lpCode">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            LP Code
                            <p-sortIcon field="lpCode"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="project">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Project
                            <p-sortIcon field="project"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="season">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Season
                            <p-sortIcon field="season"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="productCategory">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Category
                            <p-sortIcon field="productCategory"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="line">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Line
                            <p-sortIcon field="line"></p-sortIcon>
                        </div>
                    </th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-collectionPlan let-expanded="expanded">
                <tr class="p-selectable-row">
                    <td>
                        {{collectionPlan.collection}}
                    </td>
                    <td>
                        {{collectionPlan.lpCode}}
                    </td>
                    <td>
                        {{collectionPlan.project}}
                    </td>
                    <td>
                        {{collectionPlan.season}}
                    </td>
                    <td>
                        {{collectionPlan.productCategory}}
                    </td>
                    <td>
                        {{collectionPlan.line}}
                    </td>

                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td>No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<form #exportCollectionPlanForm="ngForm" (ngSubmit)="exportCollectionPlan()">
    <p-dialog header="Export Collection plan" [(visible)]="exportCollectionPlanModal" [modal]="true" [style]="{width: '800px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <hr />
        <div class="p-formgroup-inline min-h-instance-dialog-body">
            <div class="p-col-6">
                <label for="collectionPlanEdit_serviceInstance">Select template export collection plan</label>
                <p-dropdown appendTo="body" [options]="exportOptionList" [(ngModel)]="selectedExportValue"
                    [panelStyle]="{'overflow-y':'auto'}" placeholder="Select export type" optionLabel="option"
                    name="exportOptionType" [baseZIndex]="10010" required optionValue="value">
                </p-dropdown>
            </div>
        </div>
        <hr />
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeExportCollectionPlanModal()"
                class="p-button-danger"></button>
            <button pButton pRipple label="Export" icon="pi pi-check" type="submit" class="p-button-oris"></button>
        </ng-template>
    </p-dialog>
</form>