import { Component, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {  LazyLoadEvent } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { forkJoin, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AppGlobals } from "src/app/app.globals";
import { AppBreadcrumbService } from "src/app/core/breadcumb/app.breadcrumb.service";
import { ServiceInstance } from "../instances/domain/instance";
import { InstanceService } from "../instances/service/instanceservice";
import { Product } from "./domain/product";
import { ProductDetailService } from "./service/productDetail.service";
import { ExportFileType } from "src/app/enum/exportFileType.enum";
import { ImgLoadedDirective } from "./img.directive";
import { DomSanitizer } from "@angular/platform-browser";


@Component({
    templateUrl: "./productDetail.component.html",
    styleUrls: ["./productDetail.component.scss"],
})


export class ProductDetailComponent {
    private token: string;
    public productList: Product[];
    public clientsList: Document[] = [];
    public serviceInstanceList: ServiceInstance[];
    public serviceInstancesFilter: ServiceInstance[];
    public productListLoading: boolean = true;
    public productExportLoading: boolean = false; 
    public productData: Product;
    public exportFileType = ExportFileType;
    public warningModal: boolean;
    public totalRecords: number;
    public productId: string;
    public collectionPlanId: string;
    public loadingImages = true;
    event_lazy_local: LazyLoadEvent;
    itemsPerPage: number = 25;
    public imageUrl = "/assets/documents/";

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;
    @ViewChildren(ImgLoadedDirective) images: QueryList<ImgLoadedDirective>;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private route: ActivatedRoute,
        private router:Router,
        private sanitizer: DomSanitizer,
        private productService: ProductDetailService) {
        let context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([AppGlobals.setBreadCrumb('Collection Plan Products', '/collectionplans', AppGlobals.userHasPermission("IS_CLIENT"))]);
        context.event_lazy_local = {} as LazyLoadEvent;
        context.collectionPlanId = this.route.snapshot.paramMap.get("id");
        context.productId = this.route.snapshot.paramMap.get("pid");
        this.getProductData(context.productId);
        var configurations = AppGlobals.getAppConfigurations();
        if(configurations.azureStorageEnabled){
            this.imageUrl = configurations.azureStorageImagesUrl;
        }
    }

    ngOnInit(){
        this.loadingImages = true;
    }

    public sanitize(url:string){
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    public getProductData(collectionId:string)
    {
        let context = this;
        context.productService.getProductData(context.token, null, context.productId)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        context.productData = res.data;
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    public returnToCollectionPlan(){
        this.router.navigate(["/collectionplans/" + this.collectionPlanId + "/products/"]);
    }

    public returnToProductsList(){
        this.router.navigate(["/collectionplans/products/"]);
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    ngAfterViewInit() {
        var context = this;
        setTimeout(function (){
            if(context.images.length > 0){
                forkJoin(context.images.map(imgDir => imgDir.loaded)).subscribe(() => {
                    context.loadingImages = false;
                });
            } else {
                context.loadingImages = false;
            }
        }, 1000);
    }
}