<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
	<div class="card">
		<p-toolbar styleClass="p-mb-4">
			<div class="p-toolbar-group-left">
				<div class="p-d-flex p-flex-column p-flex-md-row">
					<div style="margin-right: 2rem;display:none;">
						<label for="filter_serviceIstances">Service istances</label>
						<br />
						<p-dropdown [options]="serviceInstancesFilter" #dropdown_serviceIstances
							placeholder="Select service istance" (onChange)="getList(null)" [showClear]="true"
							[style]="{'min-width':'100%'}" optionLabel="name" name="serviceIstance" optionValue="id">
						</p-dropdown>
					</div>
				</div>
			</div>
			<div class="p-toolbar-group-right">
				<button pButton label="Create Job Order" *ngIf="userHasPermission('CAN_ADD_PROJECT')" (click)="createJobOrder()" class="p-button-oris"></button>
			</div>
		</p-toolbar>
		<p-table #dtJobOrder [value]="jobOrderList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
			[globalFilterFields]="[]" (onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
			[rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="jobOrderListLoading">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
					Job Orders List
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtCustomer.filterGlobal($event.target.value, 'contains')"
							placeholder="Global Search" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="name">
						<div class="p-d-flex p-jc-between p-ai-center">
							Name
							<p-sortIcon field="name"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="description">
						<div class="p-d-flex p-jc-between p-ai-center">
							Description
							<p-sortIcon field="description"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="startTime">
						<div class="p-d-flex p-jc-between p-ai-center">
							Start time
							<p-sortIcon field="startTime"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="endTime">
						<div class="p-d-flex p-jc-between p-ai-center">
							End time
							<p-sortIcon field="endTime"></p-sortIcon>
						</div>
					</th>
					<th style="width: 8rem"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-jobOrder let-expanded="expanded">
				<tr class="p-selectable-row">
					<td>
						{{jobOrder.name}}
					</td>
					<td>
						{{jobOrder.description}}
					</td>
					<td>
						{{jobOrder.startTime | date: 'dd/MM/yyyy hh:mm:ss'}}
					</td>
					<td>
						{{jobOrder.endTime | date: 'dd/MM/yyyy hh:mm:ss'}}
					</td>
					<td>
						<button pButton type="button" *ngIf="userHasPermission('CAN_VIEW_JOB_ORDER_DATA')" title="Edit"
							icon="pi pi-pencil" class="p-button-rounded p-button-text"
							(click)="editJobOrder(jobOrder.id)"></button>
						<button pButton type="button" *ngIf="userHasPermission('CAN_DELETE_JOB_ORDER')" title="Delete"
							class="p-button-text" icon="pi pi-trash"
							(click)="deleteJobOrder(jobOrder.id, jobOrder.name)"></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td>No records found</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
<form #createJobOrderForm="ngForm" (ngSubmit)="createJobOrderForm.form.valid && saveJobOrder()">
	<p-dialog header="New Job Order" [(visible)]="jobOrderCreateModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="1000" [draggable]="false" [resizable]="false">
		<hr />
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="jobOrderNew_name">Name</label>
				<input type="text" pInputText id="jobOrderNew_name" name="jobOrderNew_name" [(ngModel)]="jobOrder_new.name"
					#name='ngModel' required />
				<div *ngIf="createJobOrderForm.submitted && name.invalid" class="error-login-info">
					<small *ngIf="name.errors?.required" class="p-error">Enter job order name</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="jobOrderNewStartDate">Start Date</label>
				<p-calendar name="jobOrderNewStartDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy" [(ngModel)]="jobOrder_new.startDate">
				</p-calendar>
			</div>
			<div class="p-col-3">
				<label for="jobOrderNewEndDate">End Date</label>
				<p-calendar name="jobOrderNewEndDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy" [(ngModel)]="jobOrder_new.endDate">
				</p-calendar>
			</div>
		</div>
		<br/>
		<br/>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="jobOrderNew_description">Description</label>
				<textarea type="text" pInputText id="jobOrderNew_description" name="jobOrderNew_description"
					[(ngModel)]="jobOrder_new.description" #description='ngModel' rows="8"></textarea>
			</div>
		</div>
		<hr />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeCreateJobOrder()"
				class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>
<form #editJobOrderForm="ngForm" (ngSubmit)="editJobOrderForm.form.valid && updateJobOrder()">
	<p-dialog header="Edit JobOrder" [(visible)]="jobOrderEditModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<hr />
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="jobOrderEdit_name">Name</label>
				<input type="text" pInputText id="jobOrderEdit_name" name="jobOrderEdit_name"
					[(ngModel)]="jobOrder_edit.name" #name='ngModel' required />
				<div *ngIf="editJobOrderForm.submitted && name.invalid" class="error-login-info">
					<small *ngIf="name.errors?.required" class="p-error">Enter jobOrder name</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="jobOrderEditStartDate">Start Date</label>
				<p-calendar name="jobOrderEditStartDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
					[(ngModel)]="jobOrder_edit.startDate">
				</p-calendar>
			</div>
			<div class="p-col-3">
				<label for="jobOrderEditEndDate">End Date</label>
				<p-calendar name="jobOrderEditEndDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy" [(ngModel)]="jobOrder_edit.endDate">
				</p-calendar>
			</div>
		</div>
		<br/>
		<br/>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="jobOrderEdit_description">Description</label>
				<textarea type="text" pInputText id="jobOrderEdit_description" name="jobOrderEdit_description"
					[(ngModel)]="jobOrder_edit.description" #description='ngModel' rows="8"></textarea>
			</div>
		</div>
		<hr />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeEditJobOrder()"
				class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>