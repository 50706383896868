export default class Translate{
    translateRoleToSelect(val:number)
    {
        switch(val)
        {
            case 1:
                return "Customer";
            case 2:
                return "Supplier";
        }
    }
}