import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AccordionModule } from "primeng/accordion";
import { ConfirmationService, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { RadioButtonModule } from "primeng/radiobutton";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SelectButtonModule } from "primeng/selectbutton";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { ToolbarModule } from "primeng/toolbar";
import { TreeModule } from "primeng/tree";
import { EmailValidator } from "src/app/utilities/email-validator.directive";
import { PasswordValidator } from "src/app/utilities/password-validator.directive";
import { UserComponent } from "./user.component";


@NgModule({
    declarations:[
        UserComponent,
        PasswordValidator,
        EmailValidator
    ],
    providers:[
        DatePipe,
        ConfirmationService,
        MessageService
    ],
    imports:[
        CommonModule, BrowserModule, BrowserAnimationsModule, ToastModule,
        SelectButtonModule, FormsModule, ReactiveFormsModule, DialogModule, ToolbarModule,
        TableModule, ConfirmDialogModule, HttpClientModule, InputTextareaModule,
        ButtonModule, TabViewModule, AccordionModule, TabMenuModule, InputTextModule,
        CheckboxModule, DropdownModule, RadioButtonModule, ScrollPanelModule, TreeModule
    ]
})
export class UserModule{}