import {Component} from '@angular/core';
import {AppComponent} from 'src/app/app.component';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
            <div class="footer-logo-container">
                <!-- IMAGE HERE -->
            </div>
            <!-- Copyright Organization -->
            <!-- <span class="copyright"></span> -->
        </div>
    `
})
export class AppFooterComponent {
    constructor(public app: AppComponent) {}
}
