<div class="p-col-12">
    <div *ngIf="loadingImages">
        <div class="p-fl-container"></div>
        <div class="p-d-flex p-fl-spinner">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </div>
    <div *ngIf="productExportLoading">
        <div class="p-fl-container"></div>
        <div class="p-d-flex p-fl-spinner">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </div>
    <div class="card" *ngIf="productData !== undefined">
        <h3 style="color: #222;">Product details
            <button *ngIf="collectionPlanId != null" style="float:right;" class="p-button-oris p-button p-component ng-star-inserted" (click)="returnToCollectionPlan();"> Return to collection plan</button>
            <button *ngIf="collectionPlanId == null" style="float:right;" class="p-button-oris p-button p-component ng-star-inserted" (click)="returnToProductsList();"> Return to products list</button>
        </h3>
        <div style="background-color: #f3f3f3;color: #000;border-radius: 5px;border: 1px solid #ddd;">
            <div class="p-formgroup-inline">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>External Id:</b> {{productData.externalId}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Transaction Id:</b> {{productData.transactionId}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>User:</b> {{productData.user}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Season:</b> {{productData.season}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Collection:</b> {{productData.collection}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Family:</b> {{productData.family}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Product category:</b> {{productData.productCategory}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Line:</b> {{productData.line}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Management:</b> {{productData.management}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>LP code:</b> {{productData.lpCode}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Version LP code:</b> {{productData.versionLPCode}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>SID:</b> {{productData.sid}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Code:</b> {{productData.code}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Description:</b> {{productData.description}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Product name:</b> {{productData.productName}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Description Eng:</b> {{productData.descriptionEng}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Product type:</b> {{productData.productType}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Subject:</b> {{productData.subject}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>User:</b> {{productData.user}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Theme:</b> {{productData.theme}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Used for:</b> {{productData.usedFor}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Group:</b> {{productData.group}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Technical group:</b> {{productData.technicalGroup}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Label nr:</b> {{productData.labelNr}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Cost:</b> {{productData.cost}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Note:</b> {{productData.note}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Original style:</b> {{productData.originalStyle}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Original season:</b> {{productData.originalSeason}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Original line:</b> {{productData.originalLine}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>UM:</b> {{productData.um}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>UM bom:</b> {{productData.umBom}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>UM buy:</b> {{productData.umBuy}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>UM sell:</b> {{productData.umSell}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Conf. site:</b> {{productData.confSite}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Embroidery site:</b> {{productData.embroiderySite}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Size range:</b> {{productData.sizeRange}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Proto size:</b> {{productData.protoSize}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Weight:</b> {{productData.weight}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Intracode:</b> {{productData.intracode}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Used size:</b> {{productData.usedSize}}</div>
            </div>
        </div>

    </div>
    <div class="card" *ngIf="productData !== undefined">
        <h3 style="color: #222;">Style Images</h3>
        <div style="background-color: #fff;color: #000;border-radius: 5px;border: 1px solid #ddd;margin-top: 10px;">
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12" style="text-align: center;">
                    <br/>
                    <img *ngIf="productData.styleImage != '' && productData.styleImage != null" [src]="sanitize(imageUrl+productData.styleImage.blobFilePath)" style="height:300px;margin:0 auto;">
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="productData !== undefined">
        <div *ngIf="productData.colors.length > 0">
            <h3 style="color: #222;">Colors</h3>
            <div style="background-color: #f3f3f3;color: #000;border-radius: 5px;border: 1px solid #ddd;">
                <div class="p-formgroup-inline" *ngFor="let colorItem of productData.colors" style="border-top:1px solid #ddd;">
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-2"><b>Color code:</b><br/> {{colorItem.colorCode}}</div>
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Description:</b><br/> {{colorItem.description}}</div>
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Description eng:</b><br/> {{colorItem.descriptionEng}}</div>
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-2"><b>Season:</b><br/> {{colorItem.season}}</div>
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-2"><b>Image color:</b><br/> <img *ngIf="colorItem.imageColor != null" [src]="sanitize(imageUrl+colorItem.imageColor.blobFilePath)" style="width:100px"/></div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="productData !== undefined">
        <h3 style="color: #222;">Images Product</h3>
        <div style="background-color: #f3f3f3;color: #000;border-radius: 5px;border: 1px solid #ddd;">
            <div class="p-formgroup-inline">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Note back :</b> {{productData.imagesProduct.noteBack}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Note front :</b> {{productData.imagesProduct.noteFront}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Note embrodeiry:</b> {{productData.imagesProduct.noteEmbrodeiry}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Note extra:</b> {{productData.imagesProduct.noteExtra}}</div>
            </div>

        </div>
        <div style="background-color: #fff;color: #000;border-radius: 5px;border: 1px solid #ddd;margin-top: 10px;">
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3" style="text-align: center;"><b>Image back</b>
                    <br/>
                    <img *ngIf="productData.imagesProduct.imageBack != '' && productData.imagesProduct.imageBack != null" [src]="sanitize(imageUrl+productData.imagesProduct.imageBack.blobFilePath)" style="width:300px;margin:0 auto;">
                </div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3" style="text-align: center;"><b>Image front </b>
                    <br/>
                    <img *ngIf="productData.imagesProduct.imageFront != '' && productData.imagesProduct.imageFront != null" [src]="sanitize(imageUrl+productData.imagesProduct.imageFront.blobFilePath)" style="width:300px;margin:0 auto;">
                </div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3" style="text-align: center;"><b>Image embrodeiry</b>
                    <br/>
                    <img *ngIf="productData.imagesProduct.imageEmbrodeiry != '' && productData.imagesProduct.imageEmbrodeiry != null" [src]="sanitize(imageUrl+productData.imagesProduct.imageEmbrodeiry.blobFilePath)" style="width:300px;margin:0 auto;">
                </div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3" style="text-align: center;"><b>Image extra</b>
                    <br/>
                    <img *ngIf="productData.imagesProduct.imageExtra1 != '' && productData.imagesProduct.imageExtra1 != null" [src]="sanitize(imageUrl+productData.imagesProduct.imageExtra.blobFilePath)" style="width:300px;margin:0 auto;">
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="productData !== undefined">
        <div *ngIf="productData.grpComposition.length > 0">
            <h3 style="color: #222;">GRP Composition</h3>
            <div style="background-color: #f3f3f3;color: #000;border-radius: 5px;border: 1px solid #ddd;">
                <div class="p-formgroup-inline" *ngFor="let grpCompositionItem of productData.grpComposition" style="border-top:1px solid #ddd;">
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Group:</b><br/> {{grpCompositionItem.group}}</div>
                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Fibers:</b>
                        <br/>
                        <div class="p-formgroup-inline" *ngFor="let grpCompositionFiberItem of grpCompositionItem.fibers" style="border-top:1px solid #ddd;">
                            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6">
                                Fiber: {{grpCompositionFiberItem.fiber}}<br/>
                                PFiber: {{grpCompositionFiberItem.pFiber}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="productData !== undefined">
        <h3 style="color: #222;">Component Treatments</h3>
        <div style="background-color: #f3f3f3;color: #000;border-radius: 5px;border: 1px solid #ddd;">
            <div class="p-formgroup-inline">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Bleach:</b> {{productData.componentTreatments.bleach}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Washing:</b> {{productData.componentTreatments.washing}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Ironing:</b> {{productData.componentTreatments.ironing}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Dry cleaning:</b> {{productData.componentTreatments.dryCleaning}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Tumble dry:</b> {{productData.componentTreatments.tumbleDry}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>F. bleach:</b> {{productData.componentTreatments.fBleach}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>F. washing:</b> {{productData.componentTreatments.fWashing}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>F. ironing:</b> {{productData.componentTreatments.fIroning}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>F. dry cleaning:</b> {{productData.componentTreatments.fDryCleaning}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>F. tumble dry:</b> {{productData.componentTreatments.fTumbleDry}}</div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="productData !== undefined">
        <h3 style="color: #222;">Sketch Images</h3>
        <div style="background-color: #f3f3f3;color: #000;border-radius: 5px;border: 1px solid #ddd;">
            <div class="p-formgroup-inline">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Inspiration note:</b> {{productData.imagesShetch.noteInspiration}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Inspiration embrodeiry note:</b> {{productData.imagesShetch.noteInspirationEmbrodeiry}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Note:</b> {{productData.imagesShetch.note}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Image inspitation:</b> <div *ngIf="productData.imagesShetch.imageInspitation != null">{{productData.imagesShetch.imageInspitation.originalFileName}}</div></div>
            </div>

        </div>
        <div style="background-color: #fff;color: #000;border-radius: 5px;border: 1px solid #ddd;margin-top: 10px;">
            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6" style="text-align: center;"><b>Image front</b>
                    <br/>
                    <img *ngIf="productData.imagesShetch.imageFront != '' && productData.imagesShetch.imageFront != null" [src]="sanitize(imageUrl+productData.imagesShetch.imageFront.blobFilePath)" style="width:300px;margin:0 auto;">
                </div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6" style="text-align: center;"><b>Image back</b>
                    <br/>
                    <img *ngIf="productData.imagesShetch.imageBack != '' && productData.imagesShetch.imageBack != null" [src]="sanitize(imageUrl+productData.imagesShetch.imageBack.blobFilePath)" style="width:300px;margin:0 auto;">
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="productData !== undefined">
        <h3 style="color: #222;">LS Fitting</h3>
        <div style="background-color: #fff;color: #000;border-radius: 5px;margin-top: 10px;">
            <div class="p-formgroup-inline">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12" *ngFor="let lsItem of productData.lstFitting">
                    <div style="background-color: #f3f3f3;color: #000;border-radius: 5px;border: 1px solid #ddd;">
                        <div class="p-formgroup-inline">
                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2">
                                <b>Whopress</b><br/>
                                {{lsItem.whopress}}
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2">
                                <b>Style note prototype</b><br/>
                                {{lsItem.styleNoteprototype}}
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 center">
                                <img *ngIf="lsItem.imagePrototype1 != null" [src]="sanitize(imageUrl+lsItem.imagePrototype1.blobFilePath)" style="width:100%;margin:0 auto;">
                                <br/>Image prototype 1
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 center">
                                <img *ngIf="lsItem.imagePrototype2 != null" [src]="sanitize(imageUrl+lsItem.imagePrototype2.blobFilePath)" style="width:100%;margin:0 auto;">
                                <br/>Image prototype 2
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 center">
                                <img *ngIf="lsItem.imagePrototype3 != null" [src]="sanitize(imageUrl+lsItem.imagePrototype3.blobFilePath)" style="width:100%;margin:0 auto;">
                                <br/>Image prototype 3
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 center">
                                <img *ngIf="lsItem.imagePrototype4 != null" [src]="sanitize(imageUrl+lsItem.imagePrototype4.blobFilePath)" style="width:100%;margin:0 auto;">
                                <br/>Image prototype 4
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>