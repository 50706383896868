import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';
import { Project } from '../domain/project';

@Injectable()
export class ProjectService {

    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getProject(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.GET, "projects/projects/"+ id, token, {});
    }

    getProjectList(token:string, event:LazyLoadEvent, serviceInstanceId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "projects", token, {
            filtersProjectsList: {
                serviceInstanceId: serviceInstanceId ?? null
            },
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    }

    getProjectsSimpleList(token:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "projects/simplelist", token, {});
    }

    createProject(token:string, project:Project):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "projects/create", token,{
            name: project.name,
            alkeniumNumber: project.alkeniumNumber,
            customerId: project.customerId,
            description: project.description,
            startTime: project.startDate,
            endTime: project.endDate,
            serviceInstanceId: project.serviceInstanceId
        });
    }

    updateProject(token:string, project:Project):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.PUT, "projects/update", token,{
                id: project.id,
                name: project.name,
                alkeniumNumber: project.alkeniumNumber,
                customerId: project.customerId,
                description: project.description,
                startTime: project.startDate,
                endTime: project.endDate,
                serviceInstanceId: project.serviceInstanceId
            });
    }

    deleteProject(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.DELETE, "projects/"+id, token, {});
    }
}