import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';
import { Customer } from '../domain/customer';

@Injectable()
export class CustomerService {

    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getCustomer(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.GET, "customers/customer/"+ id, token, {});
    }

    getCustomerList(token:string, event:LazyLoadEvent, serviceId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "customers", token, {
            filtersCustomer:{
                serviceInstanceId:serviceId ?? null
            },
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    }

    createCustomer(token:string, new_customer:Customer):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "customers/create", token,
            {
                companyName: new_customer.companyName,
                vatNumber: new_customer.vatNumber,
                fiscalCode: new_customer.fiscalCode ?? null,
                sdiCode: new_customer.sdiCode ?? null,
                erpReferenceCode: new_customer.erpReferenceCode ?? null,
                emailAddress: new_customer.emailAddress ?? null,
                pecEmailAddress: new_customer.pecEmailAddress ?? null,
                languageReference: new_customer.languageReference ?? null,
                country: new_customer.country ?? null
            });
    }

    updateCustomer(token:string, edit_customer:Customer):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.PUT, "customers/update", token,
        {
            id: edit_customer.id,
            companyName: edit_customer.companyName,
            vatNumber: edit_customer.vatNumber,
            fiscalCode: edit_customer.fiscalCode ?? null,
            sdiCode: edit_customer.sdiCode ?? null,
            erpReferenceCode: edit_customer.erpReferenceCode ?? null,
            emailAddress: edit_customer.emailAddress ?? null,
            pecEmailAddress: edit_customer.pecEmailAddress ?? null,
            languageReference: edit_customer.languageReference ?? null,
            country: edit_customer.country ?? null
        });
    }

    deleteCustomer(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.DELETE, "customers/customer/"+id, token, {});
    }
}