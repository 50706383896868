import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, LazyLoadEvent, MessageService } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { of } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppGlobals } from "src/app/app.globals";
import { AppBreadcrumbService } from "src/app/core/breadcumb/app.breadcrumb.service";
import { CustomerService } from "../customers/service/customerservice";
import { ServiceInstance } from "../instances/domain/instance";
import { InstanceService } from "../instances/service/instanceservice";
import { Product } from "./domain/product";
import { CollectionPlan } from "../collectionPlans/domain/collectionPlan";
import { ProductService } from "./service/product.service";
import { CollectionPlanService } from "../collectionPlans/service/collectionPlan.service";
import { CollectionPlanDetailsService } from "../collectionPlanDetails/service/collectionPlanDetails.service";
import { ExportFileType } from "src/app/enum/exportFileType.enum";


@Component({
    templateUrl: "./product.component.html",
    styleUrls: ["./product.component.scss"],
})


export class ProductComponent {
    private token: string;
    public productList: Product[];
    public clientsList: Document[] = [];
    public serviceInstanceList: ServiceInstance[];
    public serviceInstancesFilter: ServiceInstance[];
    public collectionPlanList: CollectionPlan[];
    public collectionPlanFilter: CollectionPlan[];
    public productListLoading: boolean = true;
    public productExportLoading: boolean = false; 
    public collectionPlanData: CollectionPlan;
    public exportFileType = ExportFileType;
    public warningModal: boolean;
    public totalRecords: number;
    public collectionPlanId: string;
    event_lazy_local: LazyLoadEvent;
    itemsPerPage: number = 25;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;
    @ViewChild("dropdown_collectionPlans")
    dropdown_collectionPlans: Dropdown;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private productService: ProductService,
        private collectionPlanService: CollectionPlanService,
        private collectionPlanDetailsService: CollectionPlanDetailsService,
        private router:Router,
        private customerService: CustomerService) {
        let context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([AppGlobals.setBreadCrumb('Collection Plan Products', '/collectionplans', AppGlobals.userHasPermission("IS_CLIENT"))]);
        context.event_lazy_local = {} as LazyLoadEvent;
        context.setServiceIstanceFilter();
        context.setCollectionPlansFilter();
        context.setServiceInstancesDropdown();
        context.setCollectionPlansDropdown();
        context.collectionPlanId = this.route.snapshot.paramMap.get("id");
        this.getCollectionPlanData(context.collectionPlanId);
    }

    getCollectionPlanData(collectionId:string)
    {
        if (collectionId !== null && collectionId !== "") {
            let context = this;
            context.collectionPlanDetailsService.getCollectionPlan(context.token, null, null, collectionId)
                .pipe(
                    tap((res) => {
                        if(res.data != null)
                        {
                            context.collectionPlanData = res.data;
                        }
                    }),
                    catchError(() => {
                        return of();
                    })
                )
                .subscribe();
        }
    }

    setServiceIstanceFilter()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstancesFilter = res.data.map((service) => {
                            return {
                            id: service.id,
                            name: service.name
                        }});
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    setCollectionPlansFilter()
    {
        this.collectionPlanService.getCollectionPlanList(this.token, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.collectionPlanFilter = res.data.map((collectionPlan) => {
                            return {
                                id: collectionPlan.id,
                                name: collectionPlan.header.name,
                            };
                        });
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    private setClientsDropdown(){
        let context = this;
        context.customerService.getCustomerList(context.token, null, null) .pipe(
            tap((response) => {
                if(response.data != null){
                    context.clientsList = response.data.map((clients) => {
                        return {
                            id: clients.id,
                            name: clients.companyName
                        }
                });
            }
        }),catchError((error) => {return of();})).subscribe();
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    public getExtensionFile(typeExport: ExportFileType) {
        let context = this;
        if (typeExport === context.exportFileType.EXCEL) {
            return "xlsx";
        } else if (typeExport === context.exportFileType.PDF) {
            return "pdf";
        } else {
            return "xlsx";
        }
    }

    public exportFile(content: string, product: Product, typeExport: ExportFileType) {
        let context = this;
        const linkSource = "data:application/octet-stream;base64," + content;
        const downloadLink = document.createElement("a");
        const fileName =
            "SCHEDA ARTICOLO " +
            (typeof product.collection !== "undefined" && product.collection !== null
                ? product.collection.replace(/[^a-zA-Z0-9 ]/g, '') + " "
                : "") +
            (typeof product.code !== "undefined" && product.code !== null
                ? product.code.replace(/[^a-zA-Z0-9 ]/g, '')
                : "") +
            "." + context.getExtensionFile(typeExport);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    private setServiceInstancesDropdown()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstanceList = res.data.map((service) => {
                            return {
                                id: service.id,
                                name: service.name
                            }
                        });
                    }
                }),
                catchError((res) => {
                    return of();
                })
            ).subscribe();
    }

    private setCollectionPlansDropdown()
    {
        this.collectionPlanService.getCollectionPlanList(this.token, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.collectionPlanList = res.data.map((collectionPlan) => {
                            return {
                                id: collectionPlan.id,
                                name: collectionPlan.header.name,
                            };
                        });
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    public getList(event:LazyLoadEvent) {
        let context = this;
        let reloadCollectionPlanData = false;
        if(event != null){
            context.event_lazy_local = event;
        }
        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;
        let dropdownCollectionPlanId = this.dropdown_collectionPlans?.value ?? null;

        if (dropdownCollectionPlanId !== null) {
            context.collectionPlanId = dropdownCollectionPlanId;
            reloadCollectionPlanData = true;
        }

        context.productListLoading = true;
        context.productService.getProductList(context.token, context.event_lazy_local, serviceInstanceId, context.collectionPlanId)
            .pipe(
                tap((res) => {
                    if(res.data != null){
                        let slice = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + context.event_lazy_local.rows));
                        context.totalRecords = res.data.length;

                        context.productList = slice.map((product) => {
                            return {
                                id: product.id,
                                serviceInstanceId: product.serviceInstanceId,
                                externalId: product.externalId,
                                transactionId: product.transactionId,
                                user: product.user,
                                project: product.project,
                                season: product.season,
                                collection: product.collection,
                                family: product.family,
                                productCategory: product.productCategory,
                                line: product.line,
                                management: product.management,
                                lpCode: product.lpCode,
                                versionLPCode: product.versionLPCode,
                                deleted: product.deleted,
                                sid: product.sid,
                                code: product.code,
                                description: product.description,
                                productName: product.productName,
                                descriptionEng: product.descriptionEng,
                                productType: product.productType,
                                subject: product.subject,
                                theme: product.theme,
                                usedFor: product.usedFor,
                                group: product.group,
                                technicalGroup: product.technicalGroup,
                                labelNr: product.labelNr,
                                cost: product.cost,
                                note: product.note,
                                originalStyle: product.originalStyle,
                                originalSeason: product.originalSeason,
                                originalLine: product.originalLine,
                                um: product.um,
                                umBom: product.umBom,
                                umBuy: product.umBuy,
                                umSell: product.umSell,
                                confSite: product.confSite,
                                embroiderySite: product.embroiderySite,
                                sizeRange: product.sizeRange,
                                protoSize: product.protoSize,
                                weight: product.weight,
                                intracode: product.intracode,
                                grpComposition: product.grpComposition,
                                componentTreatments: product.componentTreatments,
                                imagesShetch: product.imagesShetch,
                                imagesProduct: product.imagesProduct,
                                lstFitting: product.lstFitting,
                                colors: product.colors,
                                versionNumber: product.versionNumber,
                                isActive: product.isActive,
                                styleImageUrl: product.styleImageUrl,
                            }
                        });
                        context.productListLoading = false;
                    }
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                    return of();
                }),
                finalize(() => {
                    if (reloadCollectionPlanData) {
                        context.getCollectionPlanData(context.collectionPlanId);
                    }

                    if (dropdownCollectionPlanId == null && context.collectionPlanId == null) {
                        context.collectionPlanData = undefined;
                    }
                })
            )
            .subscribe();
    }

    public showProduct(id: string) {
        var context = this;
        if (typeof context.collectionPlanId !== "undefined" && context.collectionPlanId !== null) {
            this.router.navigate(["collectionplans/" + context.collectionPlanId + "/products/" + id]);
        } else {
            this.router.navigate(["collectionplans/products/" + id]);
        }
    }

    public exportProduct(id: string, product: Product, typeExport: ExportFileType){
        let context = this;
        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;
        context.productExportLoading = true;

        context.productService.exportProduct(context.token, serviceInstanceId, id, typeExport).pipe(
                tap((response) => {
                    if (typeof response.data !== 'undefined' && typeof response.data.content !== 'undefined'  && response.data.content !== '') {
                        context.exportFile(response.data.content, product, typeExport);
                        context.messageService.add({
                            severity: "success",
                            summary: "Success",
                            detail: response.message ?? "Exported the file successfully"
                        });
                    } else {
                        context.messageService.add({
                          severity: "error",
                          summary: "Error",
                          detail: "Could not export the file",
                        });
                        return of();
                    }
                }),catchError((error) => {
                    context.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: error.message ?? error.error.title
                    });
                    return of();
                }),finalize(() => {
                    context.productExportLoading = false;
            })).subscribe();
    }

}