import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseRequestMethod } from "src/app/core/base/base.service";
import { BaseService } from "src/app/core/base/base.service";

@Injectable()
export class ProductDetailService {
    
    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getProductData(token:string, serviceInstanceId:string, productId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.GET, 'products/product/full/' + productId, token, null);
    }
    
}