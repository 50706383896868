<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
    <div class="card">
        <p-toolbar styleClass="p-mb-4">
            <div class="p-toolbar-group-left">
				<div class="p-d-flex p-flex-column p-flex-md-row">
					<div style="margin-right: 2rem;">
						<label for="filter_serviceIstances">Service istances</label>
						<br/>
						<p-dropdown [options]="serviceInstancesFilter" #dropdown_serviceIstances placeholder="Select service istance"
						(onChange)="getList(null)" [showClear]="true"
						[style]="{'min-width':'100%'}" optionLabel="name" name="serviceIstance" optionValue="id"></p-dropdown>
					</div>
				</div>
			</div>
            <div class="p-toolbar-group-right">
                <button pButton label="Create Project" *ngIf="userHasPermission('CAN_ADD_PROJECT')" (click)="createProject()" class="p-button-oris"></button>
            </div>
        </p-toolbar>
        <p-table #dtProject [value]="projectList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
					[globalFilterFields]="['name', 'alkenium_number', 'customer', 'service_instance']"
					(onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
					[rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="projectListLoading">
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
							Project List
							<span class="p-input-icon-left">
								<i class="pi pi-search"></i>
								<input pInputText type="text" (input)="dtProject.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
							</span>
						</div>
					</ng-template>
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="name">
								<div class="p-d-flex p-jc-between p-ai-center">
									Name
									<p-sortIcon field="name"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="alkenium_number">
								<div class="p-d-flex p-jc-between p-ai-center">
									Alkenium Number
									<p-sortIcon field="alkenium_number"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="customer">
								<div class="p-d-flex p-jc-between p-ai-center">
									Customer
									<p-sortIcon field="customer"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="service_instance">
								<div class="p-d-flex p-jc-between p-ai-center">
									Service Instance
									<p-sortIcon field="service_instance"></p-sortIcon>
								</div>
							</th>
                            <th pSortableColumn="start_time">
								<div class="p-d-flex p-jc-between p-ai-center">
									Start Time
									<p-sortIcon field="start_time"></p-sortIcon>
								</div>
							</th>
                            <th pSortableColumn="end_time">
								<div class="p-d-flex p-jc-between p-ai-center">
									End Time
									<p-sortIcon field="end_time"></p-sortIcon>
								</div>
							</th>
							<th style="width: 8rem"></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-project let-expanded="expanded">
						<tr class="p-selectable-row">
							<td>
								{{project.name}}
							</td>
							<td>
								{{project.alkeniumNumber}}
							</td>
                            <td>
                                {{project.customer}}
                            </td>
							<td>
								{{project.serviceInstance}}
							</td>
                            <td>
                                {{project.startTime | date: 'dd/MM/yyyy hh:mm:ss'}}
                            </td>
                            <td>
                                {{project.endTime | date: 'dd/MM/yyyy hh:mm:ss'}}
                            </td>
                            <td>
                                <button pButton type="button" *ngIf="userHasPermission('CAN_EDIT_PROJECT')" title="Edit" icon="pi pi-pencil"
									class="p-button-rounded p-button-text" (click)="editProject(project.id)"
									></button>
									<button pButton type="button"  title="Orders" class="p-button-text"
									icon="pi pi-bars" (click)="openOrder(project.id)"></button>
								<button pButton type="button" *ngIf="userHasPermission('CAN_DELETE_PROJECT')" title="Delete" class="p-button-text"
									icon="pi pi-trash" (click)="deleteProject(project.id, project.name)"></button>
								
                            </td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td>No records found</td>
						</tr>
					</ng-template>
				</p-table>
    </div>
</div>
<form #createProjectForm="ngForm" (ngSubmit)="createProjectForm.form.valid && saveProject()">
	<p-dialog header="New Project" [(visible)]="projectCreateModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<hr/>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="projectNew_name">Project Name</label>
				<input type="text" pInputText id="projectNew_name" name="projectNew_name"
				[(ngModel)]="project_new.name" #name='ngModel' required/>
                <div *ngIf="createProjectForm.submitted && name.invalid" class="error-login-info">
                    <small *ngIf="name.errors?.required" class="p-error">Enter project name</small>
                </div>
			</div>
			<div class="p-col-3">
				<label for="projectNewStartDate">Start Date</label>
				<p-calendar name="projectNewStartDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
					[(ngModel)]="project_new.startDate">
				</p-calendar>
			</div>
			<div class="p-col-3">
				<label for="projectNewEndDate">End Date</label>
				<p-calendar name="projectNewEndDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
					[(ngModel)]="project_new.endDate">
				</p-calendar>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="newDocument_serviceInstance">Company Name</label>
				<p-dropdown [options]="clientsList" [(ngModel)]="project_new.customerId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select company name" optionLabel="name"
					name="projectNew_customerId" [baseZIndex]="10001" optionValue="id" #customerId='ngModel'>
				</p-dropdown>
			</div>
			<div class="p-col-3">
				<label for="projectNew_alkeniumNumber">Alkenium Number</label>
				<input type="text" pInputText id="projectNew_alkeniumNumber" name="projectNew_alkeniumNumber"
				[(ngModel)]="project_new.alkeniumNumber" #alkenium_number='ngModel' required/>
                <div *ngIf="createProjectForm.submitted && alkenium_number.invalid" class="error-login-info">
                    <small *ngIf="alkenium_number.errors?.required" class="p-error">Enter alkenium number</small>
                </div>
			</div>
			<div class="p-col-3">
				<label for="projectNew_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="project_new.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serverInstance" [baseZIndex]="10001" required optionValue="id" #service_instance='ngModel'>
				</p-dropdown>
				<div *ngIf="createProjectForm.submitted && service_instance.invalid" class="error-login-info">
					<small *ngIf="service_instance.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
		</div>
        <div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="projectNew_description">Description</label>
				<textarea type="text" pInputText id="projectNew_description" name="projectNew_description"
				[(ngModel)]="project_new.description" #description='ngModel' rows="8"></textarea>
			</div>
        </div>
		<hr/>
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeCreateProject()"
				class="p-button-danger"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit"
				class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>
<form #editProjectForm="ngForm" (ngSubmit)="editProjectForm.form.valid && updateProject()">
	<p-dialog header="Edit Project" [(visible)]="projectEditModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<hr/>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="projectEdit_name">Project Name</label>
				<input type="text" pInputText id="projectEdit_name" name="projectEdit_name"
				[(ngModel)]="project_edit.name" #name_edit='ngModel' required/>
                <div *ngIf="editProjectForm.submitted && name_edit.invalid" class="error-login-info">
                    <small *ngIf="name_edit.errors?.required" class="p-error">Enter project name</small>
                </div>
			</div>
			<div class="p-col-3">
				<label for="projectEditStartDate">Start Date</label>
				<p-calendar name="projectEditStartDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
					[(ngModel)]="project_edit.startDate">
				</p-calendar>
			</div>
			<div class="p-col-3">
				<label for="projectEditEndDate">End Date</label>
				<p-calendar name="projectEditEndDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
					[(ngModel)]="project_edit.endDate">
				</p-calendar>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="newDocument_serviceInstance">Company Name</label>
				<p-dropdown [options]="clientsList" [(ngModel)]="project_edit.customerId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select company name" optionLabel="name"
					name="projectEdit_customerId" [baseZIndex]="10001" optionValue="id" #customerId='ngModel'>
				</p-dropdown>
			</div>
			<div class="p-col-3">
				<label for="projectEdit_alkeniumNumber">Alkenium Number</label>
				<input type="text" pInputText id="projectEdit_alkeniumNumber" name="projectEdit_alkeniumNumber"
				[(ngModel)]="project_edit.alkeniumNumber" #alkenium_number_edit='ngModel' required/>
                <div *ngIf="editProjectForm.submitted && alkenium_number_edit.invalid" class="error-login-info">
                    <small *ngIf="alkenium_number_edit.errors?.required" class="p-error">Enter alkenium number</small>
                </div>
			</div>
			<div class="p-col-3">
				<label for="projectEdit_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="project_edit.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serverInstance" [baseZIndex]="10001" required optionValue="id" #service_instance_edit='ngModel'>
				</p-dropdown>
				<div *ngIf="editProjectForm.submitted && service_instance_edit.invalid" class="error-login-info">
					<small *ngIf="service_instance_edit.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
		</div>
        <div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="projectEdit_description">Description</label>
				<textarea type="text" pInputText id="projectEdit_description" name="projectEdit_description"
				[(ngModel)]="project_edit.description" #description='ngModel' rows="8"></textarea>
			</div>
        </div>
		<hr/>
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeEditProject()"
				class="p-button-danger"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit"
				class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>