import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';

@Injectable()
export class DocumentService {

    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getDocumentList(token:string, event:LazyLoadEvent, categoryId, serviceInstanceId:string, status:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "documents/list", token, {
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            filtersDocument: {
                categoryId: categoryId,
                serviceInstanceId: serviceInstanceId ?? null,
                status: status ?? null,
            },
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    }

    getDocument(token:string, documentId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.GET, 'documents/document/' + documentId, token, {});
    }

    createDocument(token:string, document:any):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, 'documents/create', token,{
            originalFileName: document.originalFileName,
            documentsCategoryId: document.documentsCategoryId,
            fileContent: document.fileContent,
            notes: document.notes,
            status: document.status,
            description: document.description,
            serviceInstanceId: document.serviceInstanceId
        });
    }

    updateDocument(token:string, document:any):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.PUT, 'documents/update', token,{
            id: document.id,
            documentsCategoryId: document.documentsCategoryId, 
            notes: document.notes,
            status: document.status,
            description: document.description,
            serviceInstanceId: document.serviceInstanceId
        });
    }

    deleteDocument(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.DELETE, 'documents/document/' + id, token, {});
    }
}